import style from "./LoginButton.module.css"

export const LoginButton = ({onClickLoginButton, type}) => {
    const theme = {
        google: {
            backgroundColor: "#ffffff",
            color: "#666666",
            iconSrc: "/img/login-logo-google.png",
            label: "Google",
        },
        facebook: {
            backgroundColor: "#1877F2",
            color: "#ffffff",
            iconSrc: "/img/login-logo-facebook.png",
            label: "Facebook",
        },
    }

    return (
        <button
            className={style.button}
            style={{
                backgroundColor: theme[type].backgroundColor,
            }}
            onClick={onClickLoginButton}
        >
            <img className={style.icon} alt="icon" src={theme[type].iconSrc} />
            <span
                className={style.label}
                style={{
                    color: theme[type].color
                }}
            >
                {theme[type].label}で続ける
            </span>
        </button>
    );
};