import React, { useState, useEffect, useLayoutEffect } from "react";

import { FormTemplate } from "./components/FormTemplate/FormTemplate";
import { FormFree } from "./components/FormFree/FormFree";
import { FormRadio } from "./components/FormRadio/FormRadio";
import { FormCheckbox } from "./components/FormCheckbox/FormCheckbox";

export function AnswerCard(props) {
  let buttons;

  const [selectValue, setSelectValue] = useState(['']); // 選択された値を格納するstate
  const [canClickNext, setCanClickNext]= useState(false);

  useEffect(() => {
    setSelectValue(props.answerd_answer);
  }, [props.answerd_answer]);

  useEffect(() => {
    if(selectValue[0] !== '' | props.answer_type === "free") {
      setCanClickNext(true);
    } else {
      setCanClickNext(false);
    }
  }, [selectValue]);

  //ラジオ変更時の挙動
  const handleChangeRadio = (event) => {
    setSelectValue(event.target.value);
  };

  //checkbox変更時の挙動
  const handleCheckboxChange = (value) => {
    if (selectValue.includes(value)) {
      // 既に選択されている場合は削除
      setSelectValue(selectValue.filter((selected) => selected !== value));
    } else {
      // 新たに選択された場合は追加
      ////初期値を削除
      let array_ = selectValue.filter(item => item !== '');
      setSelectValue([...array_, value]);
    }
  };

  //free変更時の挙動
  const handleFreeChange = (event) => {
    setSelectValue([event.target.value])
  };

  if(props.answer_type === "radio") {
    buttons = <FormRadio
      answers={props.answers}
      selecting={selectValue[0]}
      onChange={handleChangeRadio}
    />
  } else if(props.answer_type === "checkbox") {
    buttons = <FormCheckbox
      answers={props.answers}
      selecting={selectValue}
      onClick={handleCheckboxChange}
    />
  } else if(props.answer_type === "free") {
    buttons = <FormFree onChange={handleFreeChange} value={selectValue[0]} />
  };

  const prev_ = () =>{
    //parent set callback
    props.set_answer_callback(selectValue);
    props.prev_callback(selectValue);
  }

  const next_ = () =>{
    props.set_answer_callback(selectValue);
    props.next_callback(selectValue);
  }

  return (
    <FormTemplate
      title={props.question_label}
      card_num={props.card_label}
      description={props.question}
      number={props.num}
      onClickPrevious={() => prev_()}
      onClickNext={() => next_()}
      questionList={props.question_list}
      canClickNext={canClickNext}
      example={props.example}
    >
    {buttons}
    </FormTemplate>
  );
}