import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Box, TextField } from "@mui/material";
import { AnswerCard } from "./AnswerCards";

import { updateAnswerd } from "./QuestionManager";


export default function AnswerForm(props) {
    const [cards, setCards] = React.useState([]);
    const [questionList, setQuestionList] = React.useState([]);
    const [questionIndex, setQuestionIndex] = React.useState();
    const [answerList, setAnswerList] = React.useState({});

    useEffect(() => {
        setQuestionList(props.questions);
        setAnswerList(props.answers);

        const questions = props.questions;
        const question_ids = Object.keys(questions);
        setQuestionIndex(question_ids[0]);

    }, []);

    useEffect(() => {
        // //全部のカードを保持する処理
        const questions = props.questions;
        const question_ids = Object.keys(questions);
        const cards_ = {};
        for (let i=0; i < question_ids.length; i++){
            let question_id = question_ids[i];
        //     //callback
            const set_answer_callback = (answer) =>{
                const answer_ = {...answerList};
                if (answer_) {
                    answer_[question_id] = answer;
                    setAnswerList(answer_);
                }
            };
            const next_question_id = (i+1 < question_ids.length) ? question_ids[i+1] : null;
            const prev_question_id = (i-1 >= 0) ? question_ids[i-1] : null;
            const next_callback = (answer) => {
                // updateAnswer(props.user_id, props.card_id, question_id, answer);
                if (next_question_id !== null){
                    //回答中のIndexを更新
                    setQuestionIndex(next_question_id);
                    //Appの回答を更新する
                    props.update_app_callback(question_id, answer, false)
                }
                else{
                    //完了
                    // updateAnswerd(props.user_id, props.card_id, 2);
                    props.update_app_callback(question_id, answer, true);
                    props.gohome_callback();
                }
            };
            const prev_callback = (answer) => {
                props.update_app_callback(question_id, answer)
                if (prev_question_id !== null){
                    setQuestionIndex(prev_question_id);
                }
                else{
                    props.gohome_callback();
                }
            };

            const current_question = props.questions[question_id];
            const current_answers = (question_id in answerList) ? answerList[question_id] : [''];

            const head_key = Object.keys(props.questions)[0]
            const title_label = props.questions[head_key]['label'];
            const card_label = question_id.toString().slice(0, -3);
            const Card = <AnswerCard
                key={question_id.toString()}
                num={question_id}
                card_label={card_label}
                question={current_question['question']}
                question_label={title_label}
                answers={current_question['answers']}
                answer_type={current_question['answer_type']}
                question_list={questionList}
                answerd_answer={current_answers}
                question_id={question_id}
                next_callback={next_callback}
                prev_callback={prev_callback}
                set_answer_callback={set_answer_callback}
                example={current_question["example"]}
            />;
            cards_[question_id] = Card;
        }
        setCards(cards_);
    }, [answerList]);


    return (
        <>
            {cards[questionIndex]}
        </>
    );
}
