import React, { useState, useEffect } from "react";

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import Login from './Login';
import Home from './Home';
import { auth } from './components/firebase';
import { signOut } from "firebase/auth";
import CircularProgress from "@mui/material/CircularProgress";
import Div100vh from "react-div-100vh";

import style from "./App.module.css";
import "./components/GlobalStyle.css";

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ed6244',
      light: '#ffa2a3',
      dark: '#a34449'
    },
  },
});

export const handleLogout = () => {
  signOut(auth)
    .then(() => {
        console.log("logout");
    })
    .catch((error) => {
        console.log(error.message);
    });
};

const  App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <Div100vh>
        <div className={style.progress}>
          <CircularProgress />
        </div>
      </Div100vh>
    )
  } else {
    return (
      <div className={style.container}>
        {!user
          ? <div className={style.content}>
              <ThemeProvider theme={theme} >
                <Login />
              </ThemeProvider>
            </div>
          : <div className={style.content}>
              <Home user={user} />
            </div>
        }
      </div>
    );
  };
};

export default App;