import React from "react";
import style from "./BenefitCardModal.module.css";
import { Modal, IconButton, } from "@mui/material";
import { BenefitProgress } from "../BenefitProgress/BenefitProgress";
import IconClose from '@mui/icons-material/CancelRounded';
import IconRightArrow from '@mui/icons-material/ArrowForwardIosRounded';
import IconCheck from '@mui/icons-material/CheckCircleRounded';

export const BenefitCardModal = ({isShow, onClose, answerdAmount, benefits}) => {

    const isAllAnswerd = (answerdAmount) => {
        return answerdAmount >= 20 ? true : false;
    }

    return (
        <Modal
            open={isShow}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div className={style.container}>
                <div className={style.header}>
                    <div className={style.title}>
                        <span>終活を進めて特典をゲットしよう</span>
                        <IconButton
                            style={{
                                padding: "0px",
                            }}
                        >
                            <IconClose onClick={onClose} />
                        </IconButton>
                    </div>
                    <BenefitProgress answerdAmount={answerdAmount} />
                </div>
                <div className={style.content}>
                    <div className={style.current}>
                        <span className={style.text}>あなたの解答済みの質問数：</span>
                        <span className={style.amount}>{answerdAmount}</span>
                    </div>
                    <div className={style.benefits}>
                        {
                            benefits.map((benefit, index) => (
                                <button
                                    className={style.item}
                                    key={index}
                                    onClick={benefit.callback}
                                    {...(answerdAmount < benefit.require && {disabled: true})}
                                >
                                    <div className={style.label}>
                                        <div className={style.title}>
                                            <span>特典{index + 1}</span>
                                            <div className={style.require}>
                                                <IconCheck />
                                                <span>
                                                    {
                                                        isAllAnswerd(benefit.require)
                                                            ? "全ての質問に回答してゲット!"
                                                            : benefit.require + "つの質問に回答してゲット!"
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <span className={style.content}>{benefit.title}</span>
                                    </div>
                                    <div className={style.rightArrow}>
                                        {answerdAmount >= benefit.require && ( <IconRightArrow /> )}
                                    </div>
                                </button>
                            ))
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
};