import React, { useState, useEffect } from "react";
import "../GlobalStyle.css";
import style from "./QuestionHeadCard.module.css";
import { getAllAnswerCount } from "../../Stub";
import { QuestionHeadTemplate } from "../QuestionHeadTemplate/QuestionHeadTemplate";
import IconLocked from '@mui/icons-material/LockRounded';
import { QuestionHeadCardRadio } from "../QuestionHeadCardRadio/QuestionHeadCardRadio";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

export const QuestionHeadCard2 = (props) => {
  const [questions, setQuestions] = useState();
  const [answers, setAnswers] = useState();
  const [cardId, setCardId] = useState();
  const [selectValue, setSelectValue] = useState(['']); // 選択された値を格納するstate
  const [buttonList, setButtonList] = useState(); // 選択された値を格納するstate
  const [percentage, setPercentage] = useState([]); // 選択された値を格納するstate
  const [allAnswerCounts, setAllAnswerCounts] = useState(); // 選択された値を格納するstate
  const [showPercentage, setShowPercentage] = useState(false);
  const [buttonDisable, setButtonDisable ] = useState(false);

  const today = new Date();


  const question_ids = Object.keys(props.questions);
  const head_question_id = question_ids[0];
  const question_num = head_question_id.slice(0, -3)
  const head_question = props.questions[head_question_id];
  //回答済回答があれば、回答につめる。なければNULLをつめる
  //
  let head_answers = [''];
  //answerd以外のキーが1つ以上含まれていれば
  if (props.answers && Object.keys(props.answers).filter(key => key !== 'answerd').length > 0){
    head_answers = props.answers[head_question_id];
  }
  const head_question_text = head_question['question'];

  useEffect(() => {
    //answerd以外のキーが1つ以上含まれていれば
    if (props.answers && Object.keys(props.answers).filter(key => key !== 'answerd').length > 0) {
      setShowPercentage(true);
      setButtonDisable(true);
    }
    setQuestions(props.questions);
    setAnswers(props.answers);
    setCardId(props.card_id);
    setSelectValue(head_answers);
    const keys = Object.keys(head_question['answers']);
    let result;
    const answerCount = async () => {
      try {
        const user_id = props.user_id;
        // 自分以外の回答を全て取得
        result = await getAllAnswerCount(head_question_id, user_id);
        let sum = 0;
        const per = {};
        for (let i = 0; i < keys.length; i++) {
          sum += result[i];
        }
        const data = {
          sum: sum,
          counts: result
        }
        setAllAnswerCounts(data);
      } catch (error) {
        console.error('データの取得中にエラーが発生しました:', error);
      }
    };
    answerCount(); // answerCount関数を呼び出し
  }, []);

  const getPercentage = (index) => {
    let percentage = null;
    if(allAnswerCounts && selectValue && showPercentage) {
      let count = allAnswerCounts['counts'][index];
      if (selectValue[0] === index.toString()) {
        count += 1;
      }
      percentage = Math.round((count / (allAnswerCounts['sum'] + 1)) * 100);
    }
    return (percentage);
  };

  const getQuestionHeadRadio = () => {
    return head_question['answers'].map((key, index) => {
      return {answer: key.answer, progress: getPercentage(index)}
    });
  }

  //ラジオ変更時の挙動
  const handleButtonClick = (index) => {
    setShowPercentage(true);
    setSelectValue([index]);
    setButtonDisable(true);
    props.update_answer_callback(head_question_id, [index]);
  };

  return (
    <QuestionHeadTemplate
      number={question_num}
      title={head_question.label}
      onClickLaterButton={() => props.later_callback()}
      onClickAnswerButton={() => props.try_answer_callback()}
      disabled={props.disabled}
      isShowLaterButton={props.currentPage === 0 && selectValue[0] !== ""}
      isAllowedToClickAnswer={selectValue[0] !== ""}
      buttonLabel={props.buttonLabel}
    >
      <div className={style.questions}>
        <span className={style.description}>{head_question_text}</span>
        <QuestionHeadCardRadio 
          disabled={buttonDisable}
          answers={getQuestionHeadRadio()}
          selecting={selectValue[0]}
          onClick={handleButtonClick}
        />
        <div className={style.updatedDate}>
          {
            selectValue[0] !== "" && allAnswerCounts  &&
              (<span>総回答数：{allAnswerCounts['sum']+1}</span>)
          }
          {
            selectValue[0] !== "" &&
              (<span>データ更新日：{today.getFullYear()}年{today.getMonth() + 1}月{today.getDate()}日</span>)
          }
        </div>
      </div>
      <div className={`${style.tips} ${props.currentPage !== 2 && style.hide}`}>
        <span className={style.title}>{props.tips_title}</span>
        <div className={style.body}>
          <Markdown remarkPlugins={[remarkGfm]}>
            {props.tips}
          </Markdown>
        </div>
        {
          props.currentPage !== 2 && (
            <div
              className={style.overlay}>
              <IconLocked />
              <span>全ての質問に回答すると続きをお読みいただけます。</span>
            </div>
          )
        }
      </div>
    </QuestionHeadTemplate>
  );
};