import style from "./QuestionHeadCardRadio.module.css";
import "../GlobalStyle.css";
import { Button, Checkbox, LinearProgress, RadioGroup, ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useRef } from "react";

export const QuestionHeadCardRadio = ({answers, selecting, onClick, disabled}) => {

    const theme = createTheme({
        palette: {
            mode: "light",
            primary: {
                    main: "#1976d2",
                    light: "#42a5f5",
                    dark: "#1565c0"
            }
        }
    });

    const ProgressButton = ({answer, index}) => {
        const box = useRef();
        const text = useRef();

        useEffect(() => {
            if(box.current.getBoundingClientRect().width < text.current.getBoundingClientRect().width) {
                while(box.current.getBoundingClientRect().width < text.current.getBoundingClientRect().width) {
                    text.current.style.fontSize = `${Number(text.current.style.fontSize.replace("px", "")) - 1}px`;
                }
                console.log("stopped");
            };
        }, []);

        return (
            <Button
                key={index}
                disabled={disabled}
                onClick={() => onClick(index.toString())}
                variant="outlined"
                sx={{
                    width: "100%",
                    borderColor: selecting === index.toString() ? "#1e5c99" : "#b9b9b9",
                    borderRadius: "8px",
                    overflow: "hidden",
                    "&.MuiButtonBase-root": {
                        padding: "0px",
                    },
                }}
            >
                <div
                    className={style.inButton}
                    style={{
                        height: "40px",
                    }}
                >
                    <LinearProgress
                        variant="determinate"
                        value={answer.progress}
                        sx={{
                            height: "100%",
                            width: "100%",
                            "&.MuiLinearProgress-root": {
                                backgroundColor: "#ffffff",
                            },
                            ".MuiLinearProgress-bar": {
                                backgroundColor: selecting === index.toString() ? "#bccfe2" : "#ebebeb",
                            },
                        }}
                    />
                    <div
                        className={style.label}
                        style={{
                            color: selecting === index.toString() ? "#1e5c99" : "#666666"
                        }}
                    >
                        <div
                            className={style.title}
                            ref={box}
                        >
                            <span
                                className={style.text}
                                ref={text}
                                style={{fontSize: "15px"}}
                            >
                                {answer.answer}
                            </span>
                        </div>
                        <span className={style.percent}>
                            {selecting !== ""
                                ? (answer.progress + "%")
                                : ""
                            }
                        </span>
                    </div>
                </div>
                <Checkbox
                    checked={selecting === index.toString()}
                    style={{
                        display: "none",
                    }}
                />
            </Button>
        );
    };

    return (
        <ThemeProvider theme={theme} >
            <RadioGroup
                value={selecting}
            >
                <div className={style.container}>
                    {
                        answers.map((answer, index) => (
                            <ProgressButton
                                answer={answer}
                                index={index}
                            />
                        ))
                    }
                </div>
            </RadioGroup>
        </ThemeProvider>
    );
};