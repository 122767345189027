import React, { useState } from "react";
import { Button } from "@mui/material";
import style from "./LoginTemplate.module.css";
import "../GlobalStyle.css"

import IconSend from "@mui/icons-material/Send";
import { LoginPolicy } from "../LoginPolicy/LoginPolicy";

export const LoginTemplate = ({children}) => {
    const [isShow, setIsShow] = useState(false);
    const [pageType, setPageType] = useState("");

    const handleOnClickOpen = (type) => {
        setPageType(type);
        setIsShow(true);
    }

    return (
        <div className={style.container}>
            <div className={style.header}>
                <img alt="logo" src="/img/logo-white.svg" />
            </div>
            <div className={style.banner}>
                <img alt="banner" src="/img/banner.jpg" />
            </div>
            <div className={style.login}>
                <span className={style.title}>新規登録・ログイン</span>
                <div className={`${style.sociallogin} ${style.card}`}>
                    <span className={style.title}>ソーシャルログイン</span>
                    <div className={style.loginbuttons}>
                        {children}
                    </div>
                    <span>アカシカと未連携の状態でソーシャルログインを行うと新規登録扱いとなり、<button className={`${style.link} ${style.highlight}`} onClick={() => handleOnClickOpen("tou")}><span>利用規約</span></button>に同意したものとみなされます。</span>
                </div>
                <div className={`${style.cautions} ${style.card}`}>
                    <span className={style.title}>注意事項</span>
                    <ul>
                        <li><span>本サービスは全て無料でご利用いただけます。</span></li>
                        <li><span>本サービスはベータ版です。ベータ版とは、正式版をリリースする前にユーザーに使用してもらうために開発されたソフトウェアです。</span></li>
                        <li>
                            <span>本サービスが取得する個人情報の保護について、<button className={`${style.link} ${style.highlight}`} onClick={() => handleOnClickOpen("policy")}><span>プライバシーポリシー</span></button>に基づき適正に個人情報を取り扱います。</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={style.contact}>
                <img alt="logo" src="/img/logo.svg" />
                <span>ご相談・ご質問などはこちら!</span>
                <Button
                    variant="contained"
                    startIcon={<IconSend />}
                    onClick={()=>{window.open('https://docs.google.com/forms/d/e/1FAIpQLSfRAYJJZ42qpPxVIZE2v52Gd0kO146NInCnXDNF9453KZtTcw/viewform')}}
                    sx={{
                        paddingTop: "14px",
                        paddingBottom: "14px",
                        paddingLeft: "40px",
                        paddingRight: "40px",
                    }}
                >
                    お問い合わせ
                </Button>
            </div>
            <div className={style.footer}>
                <div className={style.links}>
                <button className={style.link} onClick={() => handleOnClickOpen("tou")}><span>利用規約</span></button>
                <button className={style.link} onClick={() => handleOnClickOpen("policy")}><span>プライバシーポリシー</span></button>
                </div>
                <span className={style.copyright}>Copyright 2024 Waterhuman Inc.</span>
            </div>
            <LoginPolicy
                isShow={isShow}
                pageType={pageType}
                onClose={() => setIsShow((prev) => !prev)}
            />
        </div>
    );
};