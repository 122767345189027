import style from "./SettingTemplate.module.css";
import "../GlobalStyle.css";
import ArrowLeft from "@mui/icons-material/ArrowBackIos";

export const SettingTemplate = ({title, onClickButtonBack, onClickButtonClose, children}) => {
    return (
        <div className={style.background}>
            <div className={style.header}>
                <span className={style.title}>{title}</span>
                {
                    onClickButtonBack != null &&
                        <button className={style.buttonBack} onClick={onClickButtonBack}>
                            <ArrowLeft />
                            <span>戻る</span>
                        </button>
                }
                {onClickButtonClose != null && <button className={style.buttonClose} onClick={onClickButtonClose}>閉じる</button>}
            </div>
            <div className={style.content}>
                {children}
            </div>
        </div>
    );
};