import React, { useState } from "react";
import { IconButton, Modal } from "@mui/material";
import style from "./Header.module.css";
import { Settings } from "../Settings/Settings";

export const Header = ({userData}) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <div className={style.container}>
      <IconButton size="large"><img alt="Logo" src="/img/logo.svg" /></IconButton>
      <div className={style.buttons}>
        <IconButton
          size="large"
          onClick={() => setIsShow(true)}
        >
          <img className="img-2" alt="Setting" src="/img/setting.svg" />
        </IconButton>
      </div>
      <Modal
        open={isShow}
        hideBackdrop
      >
        <Settings
          onClickButtonClose={() => setIsShow(false)}
          userData={userData}
        />
      </Modal>
    </div>
  );
};
