import style from "./PageBar.module.css";
import "../GlobalStyle.css";

import { BottomNavigation, BottomNavigationAction } from "@mui/material";

export const PageBar = ({currentPage, onClickChangePageButton}) => {

  return (
    <div className={style.page}>
      <BottomNavigation
        showLabels
        value={currentPage}
        onChange={(event, newValue) => {onClickChangePageButton(newValue)}}
        sx={{
          '&.MuiBottomNavigation-root': {
            backgroundColor: "#fdf4ed",
            borderRadius: "100px",
            height: "30px",
            width: "90%",
          },
          '.Mui-selected': {
            backgroundColor: "#ea9246",
            borderRadius: "100px",
            color: "#ffffff",
          },
        }}
      >
        <BottomNavigationAction label={<span className={style.label}>未対応</span>} />
        <BottomNavigationAction label={<span className={style.label}>後でやる</span>} />
        <BottomNavigationAction label={<span className={style.label}>回答済み</span>} />
      </BottomNavigation>
    </div>
  );
};
