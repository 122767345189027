import React, { useState, useEffect } from "react";

import { app } from "./components/firebase";
import {getMessaging, getToken} from "firebase/messaging";

import { Box, Typography } from "@mui/material";

const messaging = getMessaging(app);
async function getFirebaseMessagingToken() {
  try {
    const currentToken = await getToken(messaging, { vapidKey: "BDLcwVJq34PYOrnRSa7hmoNrlvSLrt8sJNBY48PFv2j3Ltdgz2F0J4PS-splIh7Mk82BWvYXDFAajgYU2LrA4Fs" });  // VAPIDキーを適切なものに置き換える

    if (currentToken) {
      return currentToken;
    } else {
      throw new Error('No registration token available.');
    }
  } catch (error) {
    throw error;
  }
}


export function Test(){
    const [token, setToken] = React.useState();
    (async () => {
        try {
            const token_ = await getFirebaseMessagingToken();
            setToken(token_);
            // ここでトークンを使用する処理を行います
        } catch (error) {
            console.error('Error getting Firebase Messaging Token:', error);
        }
    })();

    return(
        <Box>
            <Box sx={{ fontWeight: 'bold'}}> Tokenは 以下です </Box>
            <Box sx={{bgcolor: 'text.secondary', color: 'text.primary'}} >{token} </Box>
        </Box>
    );
}