import React from "react";
import style from "./FormCheckbox.module.css";
import { Button, Checkbox } from "@mui/material";

export const FormCheckbox = ({answers, selecting, onClick}) => {
  return (
      <div className={style.container}>
        {
          answers.map((answer, index) => (
            <Button
              key={index}
              onClick={() => onClick(index.toString())}
              variant={selecting.includes(index.toString()) ? "contained" : "outlined"}
              sx={{
                borderRadius: "100px",
                fontWeight: "700",
                color: selecting.includes(index.toString()) ? "#ffffff" : "#666666",
                textTransform: "none",
              }}
            >
              {answer.answer}
              <Checkbox
                checked={selecting.includes(index)}
                sx={{
                  display: "none",
                }}
              />
            </Button>
          ))
        }
      </div>
  );
};