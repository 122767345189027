import React, { useState, useEffect } from "react";
import style from "./Home.module.css";
import "./components/GlobalStyle.css";

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';

import IconDigital from '@mui/icons-material/SdCardRounded';
import IconAsset from '@mui/icons-material/PaidRounded';
import IconIntention from '@mui/icons-material/ChatRounded';

import Notification from "./components/Notification";

import { Button, ButtonGroup, Box, TextField } from "@mui/material";

import AnswerForm from "./AnswerForm";

import { Walkthrough } from "./components/Walkthrough/Walkthrough";
import { EntryForm } from "./components/EntryForm/EntryForm";

// import {TOKEN} from './components/firebase';

import { Test } from './CheckToken';

import { questionInit, updateAnswer, updateAnswerForPublic, updateAnswerd, resetData } from "./QuestionManager";
import { getAllAnswers } from "./Stub";

import { Header } from "./components/Header/Header";
import { BenefitCard } from "./components/BenefitCard/BenfitCard";
import { QuestionHeadCard2 } from "./components/QuestionHeadCard/QuestionHeadCard";
import { PageBar } from "./components/PageBar";
import { MenuBar } from "./components/MenuBar";
import { LibraryCard } from "./components/LibraryCard/LibraryCard";
import { LibraryLabel } from "./components/LibraryLabel/LibraryLabel";
import { LibraryHeader } from "./components/LibraryHeader/LibraryHeader";
import { get, getDatabase, ref, update, onValue, serverTimestamp } from "firebase/database";
import { Export } from "./components/Export/Export";
import Div100vh from "react-div-100vh";
import { Empty } from "./components/Empty/Empty";

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ed6244',
      light: '#ffa2a3',
      dark: '#a34449'
    }
  }
})

function Home({ user }) {
  //全質問
  const [allQuestions, setAllQuestions] = React.useState();
  //全ユーザ回答
  const [allAnswers, setAllAnswers] = React.useState({});
  //全Tips
  const [allTips, setAllTips] = React.useState();

  //回答状況
  ////未回答はanswerStatus[0], あとでやるはanswerStaatus[1], 回答済はanswerStatus[2] 
  const [answerStatus, setAnswerStatus] = React.useState();
  //現在選択している回答状況の番号 0 or 1 or 2
  const [currentAnswerStatus, setCurrentAnswerStatus] = React.useState(0);
  //現在選択している回答状況でのカード質問カード全部
  // const [questionHeadCards, setQuestionHeadCards] = React.useState();
  //現在の回答中のカードID
  const [currentCardId, setCurrentCardId] = React.useState();
  //現在の回答中のquestion id
  const [currentQuestionId, setCurrentQuestionId] = React.useState();
  //page mode  0ならHome ,1ならAnswerForm Mode
  const [pageMode, setPageMode] = React.useState(0);
  // answer form
  // const [answerPage, setAnswerPage] = React.useState();

  const [loading, setLoading] = React.useState(true);

  const [pageInstance, setPageInstance] = React.useState();


  //現在のメニューの表示状態(0:ホーム/1:ライブラリ)
  const [currentMenu, setCurrentMenu] = useState(0);

  const [requiredEntry, setRequiredEntry] = useState(false);
  const [requiredTutorial, setRequiredTutorial] = useState(false);

  const user_id = user.uid;

  //useEffect関連 stateが更新されたときはここを見る
  //////////////////////
  // コンポーネントがマウントされたときに実行される処理（componentDidMount）
  useEffect(() => {
    questionInit(user_id, updateQA);

    const db = getDatabase();
    const userRef = ref(db, `users/${user.uid}`);
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      if(data && data['user_name'] === undefined){
        setRequiredEntry(true);
      }
      if(data && data['tutorial_done'] === undefined){
        setRequiredTutorial(true);
      }
    });
    
  }, []);

  useEffect(() => {
    setLoading(false);
  } );

  const updateQA = (data) => {
    const questions = data['questions'];
    const answers = data['answers'];
    const tips = data['tips'];
    setAllQuestions(questions);
    setAllAnswers(answers);
    setAllTips(tips);
  };

  // answersが更新された時の処理
  //全部の質問をスキャンして、回答状況ごとに振り分ける
  useEffect(() => {
    //allQuestions, allAnswersが空でなければ
    const answer_status = {
      0: {},
      1: {},
      2: {}
    }
    if (allQuestions && allAnswers) {
      for (let key in allQuestions) {
        const current_question = allQuestions[key];
        const current_answer = allAnswers[key];
        const data = {
          question: current_question,
          answers: current_answer
        }
        // status
        // 0: 未回答 
        // 1: 後でやる
        // 2: 完了
        let status_ = 0;
        if (current_answer !== null && current_answer !== undefined && current_answer['answerd'] === 2) {
          status_ = 2;
        }
        else if (current_answer !== null && current_answer !== undefined && current_answer['answerd'] === 1) {
          status_ = 1;
        }
        else{
        }
        answer_status[status_][key] = data;
      }
      setAnswerStatus(answer_status);
    }
  }, [allAnswers]);

  //currentAnswerStatusが更新されたときの処理
  //未回答とか、あとでやるとか タブを選択したときに呼ばれる処理
  useEffect(() => {
    console.log('[useEffect(currentAnswerStatus, answerStatus)] call');
    //answerStatus: 1000 の問題と回答済回答のセット
    //currentAnswerStatus: 未回答, あとでやる、回答済の状態　
    // 初期値NULLでなければ走る
    if (answerStatus) {
      const targetQuestionAnswers = answerStatus[currentAnswerStatus];
      const cards = []

      //////////////////////////////////////////////////////////////////////////////////////
      //pageMode =0 ならば＝ HeadCardレンダリングモードならば
      if (pageMode == 0) {
        console.log('[useEffect(currentAnswerStatus, answerStatus)] re-render');
        //QuestionHeadCard は頭のQAだけを受け取っていたが、card_id内の全ての情報を受け取ることに変更する
        //currentAnswerStatus の一覧
        for (let card_id in targetQuestionAnswers) {
          const question_ids = Object.keys(targetQuestionAnswers[card_id]['question']);
          const current_questions = targetQuestionAnswers[card_id]['question'];
          const current_answers = targetQuestionAnswers[card_id]['answers'] ? targetQuestionAnswers[card_id]['answers'] : null;
          // const current_answers = targetQuestionAnswers[card_id]['answers'] ? targetQuestionAnswers[card_id]['answers'] : [''];

          //\n を<br> に変換する
          const tips_title = allTips[card_id]['title'];
          const current_tips = allTips[card_id]['note'];

          //回答して次へを押したときの処理
          const handleAnswer = (question_id, answer) => {
            // //db update
            let data;
            if (card_id in allAnswers) {
              data = {
                [card_id] : allAnswers[card_id]
              }
              data[card_id][question_id] = answer;
            } else {
              data = {
                [card_id]: {
                  [question_id]: answer
                }
              };
            }
            // //card_id = card_id, head_question_id = question_id
            updateAnswer(user_id, card_id, question_id, answer);
            updateAnswerForPublic(user_id, card_id, question_id, answer);
            setAllAnswers({ ...allAnswers, ...data })
            setCurrentCardId(card_id);
          }
          //Head cardの回答するボタンがおされた時の処理
          const handleTryAnswer = () => {
            setCurrentCardId(card_id);
            setPageMode(1);
          }
          const later_callback = async () => {
            const data = {
              [card_id]: {
                answerd: 1
              }
            }
            console.log({ ...allAnswers, ...data })
            //stateを更新
            setAllAnswers({ ...allAnswers, ...data })
            //DBを更新
            updateAnswerd(user_id, card_id, 1);
            // refreshAllAnswers();
          }
          let buttonLabel = ''
          if (currentAnswerStatus == 2){
            buttonLabel = '回答を確認する';
          }
          else{
            buttonLabel = '全ての質問に回答する';
          }
          let disabled ;
          if (currentAnswerStatus === 0) {
            disabled = true;
          } else {
            disabled = false;
          }
          const Card = <QuestionHeadCard2
            key={card_id.toString()}
            user_id={user_id}
            questions={current_questions}
            answers={current_answers}
            tips_title={tips_title}
            tips={current_tips}
            card_id={card_id}
            update_answer_callback={handleAnswer}
            try_answer_callback={handleTryAnswer}
            later_callback={later_callback}
            currentPage={currentAnswerStatus}
            currentCardId={currentCardId}
            buttonLabel={buttonLabel}
            disabled={disabled}
          />;
          cards.push(Card);
        }
        // setQuestionHeadCards(cards);
        setPageInstance(cards);
      }
      ////////////////////////////HeadCardここまで///////////////////////////////////////////
      ////////////////////////////AnswerFormならばここから///////////////////////////////////////////
      else {
        const ans_ = allAnswers[currentCardId] ? allAnswers[currentCardId] : [''];
        const card = <AnswerForm questions={allQuestions[currentCardId]}
          currentMenu={currentMenu}
          answers={ans_}
          user_id={user_id}
          card_id={currentCardId}
          update_app_callback={updateAppCallback}
          gohome_callback={goHomeCallback}
        />;
        setPageInstance(card);
      }
      ////////////////////////////AnswerFormならばここから///////////////////////////////////////////
    }
    setLoading(false);
  }, [answerStatus, pageMode]);

  const goHomeCallback = () => {
    setPageMode(0);
  }
  const updateAppCallback = (question_id, answer, finish) => {
    let data;
    if (currentCardId in allAnswers) {
      data = {
        [currentCardId]: allAnswers[currentCardId]
      }
      data[currentCardId][question_id] = answer;
    } else {
      data = {
        [currentCardId]: {
          [question_id]: answer
        }
      };
    }
    if (finish) {
      updateAnswerd(user_id, currentCardId, 2);
      data[currentCardId]['answerd'] = 2;
      data[currentCardId]['updated_at'] = new Date().getTime();
      setPageMode(0);
      setCurrentAnswerStatus(2);
    }
    updateAnswer(user_id, currentCardId, question_id, answer);
    setAllAnswers({ ...allAnswers, ...data })
    // setCurrentCardId(currentCardId);
  }

  const refreshAllAnswers = async () => {
    setLoading(true);
    const all_answers = await getAllAnswers(user_id);
    setAllAnswers(all_answers);
  }
  // Debug用
  const handleChangeStatus = (current_date) => {
    setCurrentAnswerStatus(current_date);
    setPageMode(0);
    refreshAllAnswers();
  }

  const handleReset = () => {
    resetData();
    refreshAllAnswers();
  }

  const handleChangeMenuType = (value) => {
    setCurrentMenu(value);
  }

  const stubBenefits = [
    {
        require: 3,
        title: "アカシカに関するインタビューに答えてAmazonギフトカード500円分がもらえる",
        callback: () => {
          window.open('https://docs.google.com/forms/d/e/1FAIpQLSeVqW7BnFVZjK-1g1FB3-xyAiuqpctwMdsdQ81jbVCG8x0J9Q/viewform');
        }
    },
    {
        require: 7,
        title: "回答した情報を他の人に共有するための「書き出し機能」をアンロック",
        callback: () => {
          setCurrentMenu(1);
        },
    },
    {
        require: 20,
        title: "経験豊富な終活アドバイザーとのオンライン個別相談会を無料でプレゼント！",
        callback: () => {
          window.open('https://docs.google.com/forms/d/e/1FAIpQLSd1xIce_ZXqQwAFCIbHhPx8gFjlCyI-Rh0d05dDCLr-xeXuUg/viewform');
        }
    },
  ];

  const libraryLabels = {
    0: {lable: "デジタル情報関連", icon: <IconDigital />},
    4: {lable: "資産関連", icon: <IconAsset />},
    13: {lable: "意思表示関連", icon: <IconIntention />},
  };

  // const handleChangeMenuType = (value) => {
  //   setCurrentMenu(value);
  // }



  const handleEntryFormUpdate  = (name, birth, address, sex, email) => {
    const db = getDatabase();
    const userRef = ref(db, `users/${user.uid}`);
    update(userRef, {
      user_name: name,
      user_birth: birth,
      user_address: address,
      user_sex: sex,
      address: email,
    }).then(() => {
      console.log('update success');
      setRequiredEntry(false);
    }).catch(() => {
      console.log('update failed');
    })
  }

  if(requiredEntry){
    console.log('user');
    console.log(user);
    return(
      <EntryForm updateCallback={handleEntryFormUpdate} userData={user} />
    )
  }

  else if(requiredTutorial){
    const handleWalkthroughUpdate = () =>{
      setRequiredTutorial(false);
      const db = getDatabase();
      const userRef = ref(db, `users/${user.uid}`);
      update(userRef, {
        tutorial_done: true
      }).then(() => {
        console.log('update success');
      }).catch(() => {
        console.log('update failed');
      })
    }

    return (
      <ThemeProvider theme={theme} >
        <Div100vh>
          <Walkthrough onClose={handleWalkthroughUpdate}/>
        </Div100vh>
      </ThemeProvider>
    )
  }

  else if(currentMenu === 0) {
    return (
      <ThemeProvider theme={theme} >
        <Div100vh>
          <div className={style.container}>
            <div className={style.header}>
              <Header reset_callback={() => handleReset()} userData={user} />
            </div>
            <div className={style.content}>
              {pageMode === 0 && answerStatus
                ? (<div style={{ display: "flex", flexDirection: "column", gap: "20px", padding: "20px", }}>
                    <BenefitCard answerdAmount={Object.keys(answerStatus[2]).length} benefits={stubBenefits}/>
                    {pageInstance}
                    {pageInstance !== undefined
                      && Object.keys(pageInstance).length === 0
                      && currentAnswerStatus !== 2
                      && <Empty />}
                  </div>)
                : (<>{pageInstance}</>)
              }
            </div>
            <div className={style.menu}>
              {pageMode === 0 &&
                <>
                  <PageBar
                    currentPage={currentAnswerStatus}
                    onClickChangePageButton={handleChangeStatus}
                  />
                  <MenuBar
                    currentMenu={currentMenu}
                    onClickChangeMenuButton={handleChangeMenuType}
                  />
                </>
              }
            </div>
          </div>
        </Div100vh>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme} >
        <Div100vh>
          <div className={style.container}>
            <div className={style.header}>
              <LibraryHeader
                username={user.displayName}
              />
            </div>
            <div className={style.content}>
              {
                pageMode === 0
                ? (Object.keys(allQuestions).map((key, index) => (
                  <>
                    {Object.keys(libraryLabels).includes(index.toString()) &&
                      <LibraryLabel
                        label={libraryLabels[index].lable}
                        icon={libraryLabels[index].icon}
                      />
                    }
                    <LibraryCard
                      key={index}
                      id={key}
                      number={index + 1}
                      title={Object.entries(allQuestions[key])[0][1].label}
                      answerStatus={answerStatus}
                      onClick={() => {
                        setPageMode(1);
                        setCurrentCardId(key);
                      }}
                    />
                  </>
                )))
                : (pageInstance)
              }
            </div>
            {pageMode === 0 &&
              <div className={style.menu}>
                <MenuBar
                  currentMenu={currentMenu}
                  onClickChangeMenuButton={handleChangeMenuType}
                />
                <Export answerStatus={answerStatus} userId={user_id} />
              </div>
            }
          </div>
        </Div100vh>
      </ThemeProvider>
    )
  }
}

export default Home;
