import { getDatabase, ref, child, onValue, get, orderByChild } from "firebase/database";

const db = getDatabase();


export async function getAllAnswerCount(question_id, user_id) {

    const percentage = {};
    // 回答ごとのカウントを初期化
    const answerCounts = {};
    // 母数をカウント
    let parameterCounts = 0;
    // 質問ごとの回答の選択肢の数
    let numberOfAnswers = 0;

    try {
        //dbからquestion_idのanswersを取得
        const questionsRef = ref(db, 'questions');
        const questionsSnapshot = await get(questionsRef);
        const questions = questionsSnapshot.val();

        for (const cardId in questions) {
            const questionId = questions[cardId];
            const options = questionId[question_id];

            if (options !== undefined) {

                // answersの要素数を取得して動的にanswerCountsを生成
                numberOfAnswers = options['answers'].length;

                for (let i = 0; i < numberOfAnswers; i++) {
                    answerCounts[i] = 0;
                }
                // 選択肢の数が取得できた場合、ループを中断
                break;
            }
        }

        // 該当の質問の回答を全ユーザから取得する
        const answersRef = ref(db, 'answersForPublic');
        const answersSnapshot = await get(answersRef);
        const answers = answersSnapshot.val();

        for (let userId in answers) {
            const userAnswers = answers[userId];
            

            for (let questionId in userAnswers) {
                const questionData = userAnswers[questionId];
                const selectedAnswers = questionData[question_id];

                // 回答されていればparameterCountsとanswerCountsのカウントを回答に応じて1増やす
                if (selectedAnswers !== undefined && user_id != userId) {
                    answerCounts[selectedAnswers] += 1;
                    parameterCounts += 1;
                }
            }
        }
        return answerCounts;
    } catch (error) {
        console.error('Error:', error);
        throw error; // エラーが発生した場合には上位にエラーを投げる
    }

}
export async function getPercentage(question_id) {

    const percentage = {};
    // 回答ごとのカウントを初期化
    const answerCounts = {};
    // 母数をカウント
    let parameterCounts = 0;
    // 質問ごとの回答の選択肢の数
    let numberOfAnswers = 0;

    try {
        //dbからquestion_idのanswersを取得
        const questionsRef = ref(db, 'questions');
        const questionsSnapshot = await get(questionsRef);
        const questions = questionsSnapshot.val();

        for (const cardId in questions) {
            const questionId = questions[cardId];
            const options = questionId[question_id];

            if (options !== undefined) {

                // answersの要素数を取得して動的にanswerCountsを生成
                numberOfAnswers = options['answers'].length;

                for (let i = 0; i < numberOfAnswers; i++) {
                    answerCounts[i] = 0;
                }

                // 選択肢の数が取得できた場合、ループを中断
                break;
            }
        }

        // 該当の質問の回答を全ユーザから取得する
        const answersRef = ref(db, 'answersForPublic');
        const answersSnapshot = await get(answersRef);
        const answers = answersSnapshot.val();

        for (let userId in answers) {
            const userAnswers = answers[userId];

            for (let questionId in userAnswers) {
                const questionData = userAnswers[questionId];
                const selectedAnswers = questionData[question_id];

                // 回答されていればparameterCountsとanswerCountsのカウントを回答に応じて1増やす
                if (selectedAnswers !== undefined) {
                    answerCounts[selectedAnswers] += 1;
                    parameterCounts += 1;
                }
            }
        }

        for (let i = 0; i < numberOfAnswers; i++) {
            percentage[i] = Math.round(answerCounts[i] / parameterCounts * 100);
        }
        return percentage;
    } catch (error) {
        console.error('Error:', error);
        throw error; // エラーが発生した場合には上位にエラーを投げる
    }

}

export async function getAllTips() {

    const tipsRef = ref(db, 'tips/');
    const tipsDataDictionary = {};

    try {
        const snapshot = await get(tipsRef);
        const allTips = snapshot.val();

        const promises = Object.keys(allTips).map((key) => {
            const tipsData = allTips[key];
            tipsDataDictionary[key] = tipsData;
            const tipRef = child(tipsRef, String(key));
            return get(tipRef);
        });

        await Promise.all(promises);
        return tipsDataDictionary;

    } catch (error) {
        console.error('Error:', error);
        throw error; // エラーが発生した場合には上位にエラーを投げる
    }

}

export async function getAllAnswers(user_id) {
    // user_idの回答データをとる
    const answersRef = ref(db, 'answers/' + user_id);
    const answersDataDictionary = {};

    try {
        const snapshot = await get(answersRef);
        const answerData = snapshot.val();
        const promises = Object.keys(answerData).map((key) => {
            const answersData = answerData[key];
            answersDataDictionary[key] = answersData;
            const answerRef = child(answersRef, String(key));
            return get(answerRef);
        });

        await Promise.all(promises);
        return answersDataDictionary;

    } catch (error) {
        console.error('Error:', error);
        throw error; // エラーが発生した場合には上位にエラーを投げる
    }
}

export async function getAllQuestions() {

    const questionsRef = ref(db, 'questions/');
    const questionsDictionary = {};

    try {
        const snapshot = await get(questionsRef);
        const questionData = snapshot.val();
        const promises = Object.keys(questionData).map((key) => {
            const questionsData = questionData[key];
            questionsDictionary[key] = questionsData;
            const questionRef = child(questionsRef, String(key));
            return get(questionRef);
        });

        await Promise.all(promises);
        return questionsDictionary;

    } catch (error) {
        console.error('Error:', error);
        throw error; // エラーが発生した場合には上位にエラーを投げる
    }
}

