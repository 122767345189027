import style from "./EntryForm.module.css";
import "../GlobalStyle.css";
import { Button } from "@mui/material";
import { useState } from "react";

import PREFECTURES from "./prefecture";


export const EntryForm = ({updateCallback, userData}) => {
    const [name, setName] = useState("");
    const [birth, setBirth] = useState("");
    const [sex, setSex] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState(userData.email);

    const handleOnSubmit = () => {
        console.log("name", name);
        if((name === undefined) || (name === "")){
            alert("氏名を入力してください");
        } else if((birth === undefined) || (birth === "")){
            alert("生年月日を入力してください");
        } else if((sex === undefined) || (sex === "")){
            alert("性別を入力してください");
        } else if((email === undefined) || (email === "")){
            alert("メールアドレスを入力してください");
        } else if((address === undefined) || (address === "")){
            alert("住所を入力してください");
        } else {
            updateCallback(
                name,
                birth,
                address,
                sex,
                email,
            );
        };
    };

    const defaultEmail = userData.email;

    return (
        <div className={style.container}>
            <div className={style.header}>
                基本情報の入力
            </div>
            <div className={style.input}>
                <div className={style.label}>
                    <span>氏名</span><span className={style.required}>必須</span>
                </div>
                <input type="text" placeholder="例) 終活 花子" onChange={(event) => setName(event.target.value)} />
            </div>
            <div className={style.input}>
                <div className={style.label}>
                    <span>生年月日</span><span className={style.required}>必須</span>
                </div>
                <input type="date" onChange={(event) => setBirth(event.target.value)}/>
            </div>
            <div className={style.input}>
                <div className={style.label}>
                    <span>性別</span><span className={style.required}>必須</span>
                </div>
                <select onChange={(event) => setSex(event.target.value)}>
                    <option value=""></option>
                    <option value="男性">男性</option>
                    <option value="女性">女性</option>
                    <option value="回答しない">回答しない</option>
                </select>
            </div>
            <div className={style.input}>
                <div className={style.label}>
                    <span>居住地</span><span className={style.required}>必須</span>
                </div>
                <select onChange={(event) => setAddress(event.target.value)}>
                    <option value=""></option>
                    {PREFECTURES.map((prefecture, index) => (
                        <option value={prefecture} key={index}>{prefecture}</option>
                    ))}
                </select>
            </div>
            <div className={style.input}>
                <div className={style.label}>
                    <span>メールアドレス (自動入力)</span>
                </div>
                <input type="text" defaultValue={defaultEmail} onChange={(event) => setEmail(event.target.value)} />
            </div>
            <div className={style.button}>
                <Button
                    onClick={handleOnSubmit}
                    variant="contained"
                    sx={{
                        "&.MuiButton-root": {
                            boxShadow: "none",
                            fontSize: "18px",
                            color: "#ffffff",
                            background: "linear-gradient(0.25turn, #EA5A46, #EA9246)",
                        },
                    }}
                >
                    完了
                </Button>
            </div>
        </div>
    );
};