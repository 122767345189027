import { signInWithPopup, onAuthStateChanged } from "@firebase/auth";
import React, { useEffect, useState } from "react";
import { googleProvider, facebookProvider } from "./components/firebase";
import { auth } from "./components/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { ref, set, getDatabase, serverTimestamp, get, child } from 'firebase/database';
import Button from '@mui/material/Button';
import { LoginTemplate } from "./components/LoginTemplate/LoginTemplate";
import { LoginButton } from "./components/LoginButton/LoginButton";

const db = getDatabase();

function Login() {
    const [user] = useAuthState(auth);
    const [userInfo, setUserInfo] = useState({ uid: null, email: null });
    // onAuthStateChangedでユーザの認証状態が変更されるたびにコールバックを実行
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                // ユーザがサインインしている場合、ユーザのUIDを取得
                setUserInfo({ uid: user.uid, email: user.email });
                // 既に登録されているユーザーかどうかを確認
                const userRef = ref(db, `users/${user.uid}`);
                const snapshot = await get(child(userRef, 'last_access'));

                if (snapshot.exists()) {
                  // 既に登録されているユーザーの場合、last_accessを更新
                  updateLastAccess(user.uid);
                } else {
                  // 新規ユーザーの場合、ユーザーデータを保存
                  saveUserDataToDatabase(user.uid, user.displayName, user.email);
                }
            } else {
                // ユーザーがサインアウトしている場合、UIDをnullに設定
                setUserInfo({ uid: null, email: null });
            }
        });
        // コンポーネントがアンマウントされる時にunsubscribe
        return () => unsubscribe();
    }, []);

    // Realtime Databaseにユーザデータを保存する関数
    const saveUserDataToDatabase = (uid, name, address) => {
    const userRef = ref(db, `users/${uid}`);
    const answersRef = ref(db, `answers/${uid}`);
    const answersForPublicRef = ref(db, `answersForPublic/${uid}`);
    const timestamp = serverTimestamp(); 

    set(userRef, {
        address, 
        created_at: timestamp,
        last_access: timestamp,
        name,
       });
    set(answersRef, {
      initial:true
    });
    set(answersForPublicRef, {
      initial:true
    });
  };
  
    // last_accessを更新する関数
    const updateLastAccess = (uid) => {
    const userRef = ref(db, `users/${uid}`);
    set(child(userRef, 'last_access'), serverTimestamp());
    };

    return (
      <LoginTemplate>
        <SignInWithGoogleButton />
        <SignInWithFacebookButton />
      </LoginTemplate>
    );
}

export default Login;

//Googleでサインイン
function SignInWithGoogleButton() {
    const signInWithGoogle = () => {
        //firebaseを使ってログインする。
        signInWithPopup(auth, googleProvider)
        .catch((error) => {
            if (error.code !== 'auth/popup-closed-by-user') {
                console.error('Firebase: Error', error);
            }
        });
    };
    return (
      <LoginButton onClickLoginButton={signInWithGoogle} type={"google"} />
    );
}

//Facebookでサインイン
function SignInWithFacebookButton() {
  const signInWithFacebook = () => {
      //firebaseを使ってログインする。
      signInWithPopup(auth, facebookProvider)
      .catch((error) => {
        if (error.code !== 'auth/popup-closed-by-user') {
            if (error.code === 'auth/account-exists-with-different-credential') {
                document.getElementById('error-message').innerText = 'お客様はGoogleアカウントでサービスをご利用中です。Googleでログインをお試しください。';
            } else {
                console.error('Firebase: Error', error);
            }
        }
      });
  };
  return (
      <>
        <LoginButton onClickLoginButton={signInWithFacebook} type={"facebook"} />
        <p id="error-message" style={{ color: 'red' }}></p>
      </>
  );
}

// //Twitterでサインイン
// function SignInWithTwitterButton() {
//     const signInWithTwitter = () => {
//         //firebaseを使ってログインする。
//         signInWithPopup(auth, tiwitterProvider);
//     };

//     return (
//         <button onClick={signInWithTwitter}>
//             <p>Sign in with Twitter</p>
//         </button>
//     );
// }

// function SignInWithYahooButton() {
// const Yahooauth = getAuth();
// signInWithPopup(Yahooauth, yahooProvider)
//    .then((result) => {
//      // IdP data available in result.additionalUserInfo.profile
//      // ...

//     // Yahoo OAuth access token and ID token can be retrieved by calling:
//      const credential = OAuthProvider.credentialFromResult(result);
//      const accessToken = credential.accessToken;
//      const idToken = credential.idToken;
//    })
//    .catch((error) => {
//      // Handle error.
//    });
// //Yahooでサインイン
// function SignInWithYahooButton() {
//     const SignInWithYahoo = () => {
//         //firebaseを使ってログインする。
//         signInWithPopup(auth, yahooProvider);
//     };

//     return (
//         <button onClick={SignInWithYahoo}>
//             <p>Sign in with Yahoo</p>
//         </button>
//     );
// }

