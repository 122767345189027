import React from "react";
import style from "./FormFree.module.css";
import { TextField } from "@mui/material";

export const FormFree = ({onChange, value}) => {
  return (
    <div className={style.container}>
      <TextField
        placeholder="入力してください"
        multiline
        value={value}
        onChange={onChange}
        rows={10}
        style={{
          width: "100%",
          backgroundColor: "#ffffff",
        }}
      />
    </div>
  );
};