import style from "./Settings.module.css";
import "../GlobalStyle.css";
import ArrowRight from "@mui/icons-material/ArrowForwardIos";
import { SettingTemplate } from "../SettingTemplate/SettingTemplate";
import { MarkdownParser } from "../MarkdownParser/MarkdownParser";
import { forwardRef, useState } from "react";

import policy_md from './policy.md';
import term_md from './termofuse.md';
import { SettingAccount } from "../SettingAccount/SettingAccount";
import { Walkthrough } from "../Walkthrough/Walkthrough";

export const Settings = ({onClickButtonClose, userData}) => {
    const values = [
        {title: "アカウント設定", callback: () => {setSettingPage(1)}},
        {title: "使い方", callback: () => {setSettingPage(2)}},
        {title: "お問合わせ", callback: () => {window.open('https://docs.google.com/forms/d/e/1FAIpQLSfRAYJJZ42qpPxVIZE2v52Gd0kO146NInCnXDNF9453KZtTcw/viewform')}},
        {title: "プライバシーポリシー", callback: () => {setSettingPage(3)}},
        {title: "利用規約・免責事項", callback: () => {setSettingPage(4)}},
    ];

    const PageSetting = forwardRef((props, ref) => (
        <ul className={style.content} {...props} ref={ref}>
        {
            values.map((item, index) => (
                <li className={style.item} key={index}>
                    <button onClick={item.callback}>
                        <span>{item.title}</span>
                        <ArrowRight />
                    </button>
                </li>
            ))
        }
        </ul>
    ));

    const PageTermsOfUse = forwardRef((props, ref) => (
        <div {...props} ref={ref} className={style.modalRef}>
            <MarkdownParser md={term_md} />
        </div>
    ));

    const PagePolicy = forwardRef((props, ref) => (
        <div {...props} ref={ref} className={style.modalRef}>
            <MarkdownParser md={policy_md} />
        </div>
    ));

    const PageAccountSettings = forwardRef((props, ref) => (
        <div {...props} ref={ref} className={style.modalRef}>
            <SettingAccount userData={userData}/>
        </div>
    ));

    const PageWalkthrough = forwardRef((props, ref) => (
        <div {...props} ref={ref} className={style.modalRef}>
            <Walkthrough onClose={() => {setSettingPage(0)}}/>
        </div>
    ));

    const pages = [
        {
            title: "設定一覧",
            component: <PageSetting />,
            onClickButtonClose: onClickButtonClose,
            onClickButtonBack: null,
        },
        {
            title: "アカウント設定",
            component: <PageAccountSettings />,
            onClickButtonClose: null,
            onClickButtonBack: () => {setSettingPage(0)},
        },
        {
            title: "使い方",
            component: <PageWalkthrough />,
            onClickButtonClose: null,
            onClickButtonBack: () => {setSettingPage(0)},
        },
        {
            title: "プライバシーポリシー",
            component: <PagePolicy />,
            onClickButtonClose: null,
            onClickButtonBack: () => {setSettingPage(0)},
        },
        {
            title: "利用規約・免責事項",
            component: <PageTermsOfUse />,
            onClickButtonClose: null,
            onClickButtonBack: () => {setSettingPage(0)},
        },
    ];

    const [settingPage, setSettingPage] = useState(0);

    return (
        <SettingTemplate
            title={pages[settingPage].title}
            onClickButtonClose={pages[settingPage].onClickButtonClose}
            onClickButtonBack={pages[settingPage].onClickButtonBack}
        >
            {pages[settingPage].component}
        </SettingTemplate>
    );
};