import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getDatabase } from "firebase/database";
import {ref, set} from 'firebase/database';
import { connectDatabaseEmulator } from "firebase/database";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { OAuthProvider,GoogleAuthProvider, TwitterAuthProvider, FacebookAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBQ59pR1By9-Xj3nRlWGwKG6pUqp678jKA",
  authDomain: "akashica-82364.firebaseapp.com",
  projectId: "akashica-82364",
  storageBucket: "akashica-82364.appspot.com",
  messagingSenderId: "245185328391",
  appId: "1:245185328391:web:92647d3ee221d2a4b981a2",
  measurementId: "G-ZVG8V7RJPN",
  // databaseURL: "http://127.0.0.1:9000"
  databaseURL: "https://akashica-82364-default-rtdb.firebaseio.com"
};

export const app  = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
// export const tiwitterProvider = new TwitterAuthProvider();
// export const yahooProvider = new OAuthProvider('yahoo.com');
export const messaging = getMessaging(app);
export const database = getDatabase(app);

if (window.location.hostname === "localhost") {
  // Point to the RTDB emulator running on localhost.
  connectDatabaseEmulator(database, "127.0.0.1", 9000);
  connectAuthEmulator(auth,"http://localhost:9099");  
} 

// export const requestForToken = () => {
//   return getToken(messaging, { vapidKey: "BDLcwVJq34PYOrnRSa7hmoNrlvSLrt8sJNBY48PFv2j3Ltdgz2F0J4PS-splIh7Mk82BWvYXDFAajgYU2LrA4Fs" })
//     .then((currentToken) => {
//       if (currentToken) {
//         console.log("current token for client: ", currentToken);
//       } else {
//         console.log("No registration token available. Request permission to generate one.");
//       }
//     })
//     .catch((err) => {
//       console.log("An error occurred while retrieving token. ", err);
//     });
// };
////////////////////////////////////////////////

export function TOKEN(){
getToken(messaging, { vapidKey: "BDLcwVJq34PYOrnRSa7hmoNrlvSLrt8sJNBY48PFv2j3Ltdgz2F0J4PS-splIh7Mk82BWvYXDFAajgYU2LrA4Fs" }).then((currentToken) => {
  if (currentToken) {
    console.log('CALL TOKEN ', currentToken);
    return (
      <div>
        <h1> testeststetstse</h1>
        CURRENT TOKEN is 
        {currentToken}
      </div>
    )
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');

    return (
      <h1> test2</h1>
    )
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
    return (
      <h1> test3</h1>
    )
  // ...
});
}
////////////////////////////////////////////////


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });

//   //////////////////////////////
// export function writeUserData(userId, name, email, imageUrl) {
//   const db = database;
//   set(ref(db, 'users/' + userId), {
//     username: name,
//     email: email,
//     profile_picture : imageUrl
//   });
// }