import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material";
import style from "./QuestionHeadTemplate.module.css";
import "../GlobalStyle.css";

import IconDownArrow from '@mui/icons-material/ExpandCircleDownRounded';

export const QuestionHeadTemplate = ({children, number, title, onClickLaterButton, onClickAnswerButton, disabled, isShowLaterButton, buttonLabel, isAllowedToClickAnswer}) => {
    return (
        <Accordion
            defaultExpanded={disabled}
            disableGutters={true}
            disabled={disabled}
            sx={{
                backgroundColor: "red",
                '&.MuiAccordion-root': {
                    opacity: 1,
                    backgroundColor: "#ffffff",
                    borderRadius: "12px",
                    boxShadow: "0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
                },
                '&::before': {
                    display: "none",
                },
            }}
        >
            <AccordionSummary
                expandIcon={disabled ? null : <IconDownArrow />}
                sx={{
                    'svg': {
                        color: "#666666",
                    },
                    '&.Mui-disabled': {
                        opacity: 1,
                    }
                }}
            >
                <div className={style.header}>
                    <span className={style.number}>No.{number}</span>
                    <span className={style.title}>{title}</span>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                {children}
                <div className={style.buttons}>
                    {
                        isShowLaterButton && (
                            <Button
                                onClick={onClickLaterButton}
                                variant="text"
                                sx={{
                                    borderRadius: "100px",
                                    fontWeight: "700",
                                    color: "#ea9246",
                                    marginRight: "auto",
                                }}
                            >
                                後でやる
                            </Button>
                        )
                    }
                    <Button
                        onClick={onClickAnswerButton}
                        variant="contained"
                        disabled={!isAllowedToClickAnswer}
                        sx={{
                            borderRadius: "100px",
                            fontWeight: "700",
                            color: "#ffffff",
                            marginLeft: "auto",
                        }}
                    >
                        {buttonLabel}
                    </Button>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};