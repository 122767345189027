import style from "./MenuBar.module.css";
import "../GlobalStyle.css";

import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import IconHome from '@mui/icons-material/HomeRounded';
import IconLibrary from '@mui/icons-material/ArticleRounded';

export const MenuBar = ({currentMenu, onClickChangeMenuButton}) => {

  return (
    <div className={style.menu}>
      <BottomNavigation
        showLabels
        value={currentMenu}
        onChange={(event, newValue) => {onClickChangeMenuButton(newValue)}}
      >
        <BottomNavigationAction label="ホーム" icon={<IconHome />} />
        <BottomNavigationAction label="ライブラリ" icon={<IconLibrary />} />
      </BottomNavigation>
    </div>
  );
};
