import { getDatabase, ref, onValue, child, get, update, serverTimestamp } from "firebase/database";

import { getAllQuestions, getAllAnswers, getAllTips, getPercentage} from "./Stub";

const db = getDatabase();

// export function updateCurrentDate(user_id, current_date){
//   const userRef = ref(db, 'users/' + user_id)
//   const updateData = {
//     current_date: current_date
//   };

//   update(userRef, updateData)
//   .then( () => {
//     console.log('update success');
//   })
//   .catch( () =>{
//     console.log('update failed');
//   })
// }
export function updateAnswer(user_id, card_id, question_id, answer){
  const answersRef = ref(db, `answers/${user_id}/${card_id}/`);

  const updateData = {
    [question_id] : answer
  }
  update(answersRef, updateData)
  .then( () => {
    // console.log('[updateAnswer]update success');
  })
  .catch( () =>{
    console.log('update failed');
  })
}

export function updateAnswerForPublic(user_id, card_id, question_id, answer){
    // question_idの下一桁が1の場合は回答を保存
    if (question_id % 10 === 1) {
      const answersCopyRef = ref(db, `answersForPublic/${user_id}/${card_id}/`);
      const updateAnswerCount = {
        [question_id] : answer
      }
      update(answersCopyRef, updateAnswerCount)
      .then( () => {
          console.log('[updateAnswer]update success');
      })
      .catch( () =>{
        console.log('update failed');
      })
    }
}

//未回答・あとで・完了のStatusをセットする関数
export function updateAnswerd(user_id, question_id, answerd){
  const answersRef = ref(db, `answers/${user_id}/${question_id}`);
  const updateData = {
    answerd: answerd,
    updated_at: serverTimestamp(),
}

  update(answersRef, updateData)
  .then( () => {
    console.log('update success');
  })
  .catch( () =>{
    console.log('update failed');
  })
}

export async function questionInit(user_id, updateQA){
  const questions = await getAllQuestions();
  const answers = await getAllAnswers(user_id);
  const tips = await getAllTips();

  const Percentage = await getPercentage(user_id);
  
  //console.log('call stub questionInit');
  // console.log(questions);



  updateQA({
    questions: questions,
    answers: answers,
    tips: tips
  });
}

// export function getQuestionTodayDebug(user_id, updateQA) {
   // ユーザのcurrent_dateを取得
//   const userRef = ref(db, 'users/' + user_id + '/current_date');
//   get(userRef)
//     .then((snapshot) => {
//       const currentDate = snapshot.val()
//       // question-ordersからcurrent_dateに対応するリストを取得
//       const questionOrdersRef = ref(db, 'question-orders/' + currentDate);
//       return get(questionOrdersRef);
//     })
//     .then((snapshot) => {
//       const questionIds = snapshot.val();
//       // questionsから詳細な情報を取得
//       const questionsRef = ref(db, 'questions');
//       const promises = questionIds.map((questionId) => {
//         const questionIdRef = child(questionsRef, String(questionId));
//         return get(questionIdRef);
//       });

//       return Promise.all(promises);
//     })
//     .then((questionSnapshots) => {
//       // 取得した質問の情報を表示または利用
//       const answersRef = ref(db, 'answers');
//       const questionDataDictionary = {};
//       questionSnapshots.forEach((questionSnapshot) => {
//         const key = questionSnapshot.key;
//         const questionData = questionSnapshot.val();
//         questionDataDictionary[key] = questionData;
//       });
//
//       const keys = Object.keys(questionDataDictionary);
//       const promises = keys.map((key) => {
//         const answerRef = child(answersRef, String(key));
//         return get(answerRef);
//       });
//       return Promise.all([questionDataDictionary, Promise.all(promises)]);
//     })
//     .then(([questionDataDictionary, answersSnapshots]) => {
//       const answersDataDictionary = {};
//       answersSnapshots.forEach((answersSnapshot) => {
//         const key = answersSnapshot.key;
//         const answersData = answersSnapshot.val();
//         answersDataDictionary[key] = answersData;
//       });
//       // const answersData = answersSnapshot.val() || {}; // 存在しない場合は空のオブジェクト
//       console.log('In question Manager');
//       console.log('questions', questionDataDictionary);
//       console.log('answers', answersDataDictionary);
//       const question_ids = Object.keys(answersDataDictionary);
//       updateQA({ questions: questionDataDictionary, answers: answersDataDictionary, question_ids: question_ids, current_qn: 0 });
//     })
//     .catch((error) => {
//       console.error('Error:', error);
//     });
//   }

  //////////////////////////////////



////
export function resetData() {
const data = {
    "answers": {
        "900": {
            "100": {
                "answerd": 2,
                "1001": [
                    "1"
                ],
                "1002": [
                    "1",
                    "2"
                ],
                "1003": [
                    "5",
                    "7"
                ],
                "1004": [
                    "test_string"
                ]
            },
            "200": {
                "answerd": 1,
                "2001": [
                    "1"
                ],
                "2002": [
                    "1",
                    "2"
                ]
            }
        }
    },
    "users": {
        "900": {
            "name": "yama",
            "address": "hogee@hoge.com",
            "current_date": 0,
            "created_at": 1704361614,
            "last_access": 1704361614
        },
        "901": {
            "name": "aaaaaaa",
            "address": "hogee@hoge.com",
            "current_date": 1,
            "created_at": 1704361614,
            "last_access": 1704361614
        }
    },
    "tips": {
        "100": {
            "title": "契約中のサービスが分からない時は…",
            "note": "サブスクリプションサービスは、各サービスサイトやアプリストアなど契約経路が様々です。ご自身で契約を忘れてしまっている可能性もあるので、決済元を確認し、契約中のサービスを洗い出しておきましょう。契約によっては年契約のものもあるので、一年分確認しておくと安心です。\n 【ここを確認！】\n ・クレジットカード利用明細\n ・携帯利用料明細\n ・銀行口座自動引き落とし"
        },
        "200": {
            "title": "アカウント情報は紙に残そう",
            "note": "遺族がSNSアカウントの削除手続きをする場合、各サービスに問い合わせをすれば、基本的に本人のログインIDやパスワードは不要です。\n ただし、遺族がログインできた方が削除の手間が少なく済むのも事実です。\n \n そこでおすすめのアカウント共有方法が、紙のメモを残しておくことです。\n オンライン上に残してしまうと、情報漏洩のリスクがあるためあまりおすすめできません。\n また、SNSアカウントであれば、銀行口座の暗証番号などと違い、家族に知られたとしても、トラブルになるリスクは小さいです。家族にアカウント情報を共有する場合には、紙にメモをし、保管場所をこのサービス上に登録しておきましょう。"
        },
        "300": {
            "title": "Googleアカウントの便利機能",
            "note": "メールアドレスは特に問題ありませんが、アカウント自体を家族に共有するのに抵抗がある方は多いのではないでしょうか？\n そこで便利なのがGoogle公式の「アカウント無効化管理ツール」です。\n 指定した期間、アカウントを利用していない状態が続いた場合、他のユーザーにデータを共有したり、アカウントを削除することができる機能です。Googleの場合は、メールに限らず、ドライブに保存しているファイルやGoogleフォトの写真も共有できるので便利です。ぜひ設定しておきましょう。"
        },
        "400": {
            "title": "写真整理のおすすめサービスを紹介",
            "note": "・Google Photo\n Google フォトは写真・動画用のクラウドストレージ サービスです。アカウント1つにつき15GBまで無料で利用できます。/n Googleアカウントは複数作成できるので、普段使いのアカウントと使い分けるのも便利です。写真を整理し、家族にリンクを伝えておけば共有も簡単にできます。前回紹介した「アカウント無効化管理ツール」も利用可能です。/n ・節目写真館/n アナログ写真をスキャンし、一括でデジタル化してくれるサービスです。アルバムのまま依頼できるので、一枚一枚写真を抜き出す手間もかかりません。"
        },
        "500": {
            "title": "iPhoneのデジタル遺産プログラム",
            "note": "iOS 15.2(macOS 12.1)以降、自分のApple IDに故人アカウント管理連絡先を指定できるようになりました。これによりもしもの際には、メール、連絡先、メモ、ボイスメモ、アプリ、iCloud写真などを家族に共有することができます。/n この機能を活用し、デフォルトのメモアプリに「端末のパスコード」を残しておけば、自分の死後でも家族は端末を開けるようになります。/n 尚、管理連絡先のユーザーがデータにアクセスするには、アカウント所有者の死亡の記載がある戸籍謄本が必要となりますので、生前に共有されることはありません。"
        },
        "600": {
            "title": "10年間取引のない口座は休眠預金に…",
            "note": "10年間取引のない預金は、「休眠預金」として扱われ、民間での公的な支援に活用されています。休眠預金は、年間で1,200億円程度発生していると言われています。/n あなたの預金が休眠預金になったとしても、いつでも引き出しは可能ですが、基本的には窓口での手続きが必要です。また金融機関によっては管理手数料がかかる場合もあるので注意しましょう。"
        },
        "700": {
            "title": "クレジットカードを複数持ちするデメリット",
            "note": "契約時のポイントプレゼントなどに魅了され、クレジットカードを複数枚利用している方も多いのではないでしょうか。/n ここでは終活視点で、クレジットカードを複数枚利用することのデメリットを紹介します。/n ・年会費の支払い/n 年会費のかかるクレジットカードの場合、当然費用がかかります。/n ・悪用リスク/n オンライン化により、請求明細書が送られてこないので不正に気づかなかったという場合があります。/n・ポイントの消滅/n クレジットカード会社により異なりますが、貯めたポイントは、相続できないものがほとんでです。一方、JALやANAのマイレージは引き継ぐことができます。契約内容をあらかじめ確認しておきましょう。/n ・解約手続きの煩雑化/n ご本人が亡くなった後、家族が解約手続きを行う場合、契約者本人との関係を証明する書類の準備など、やや煩雑な郵送のやりとりが発生します。"
        },
        "800": {
            "title": "相続時に電子マネーは払い戻せる？",
            "note": "相続時、電子マネーの払い戻しが可能かどうかは、各サービスにより異なります。代表的なサービスについてまとめましたので確認しておきましょう。/n ・Suica、PASMO、ICOCAなど交通系IC/n →払い戻し可能。/n ・PayPay/n →払い戻し可能。/n ・楽天Edy/n →払い戻し不可。残高の利用可能。/n ・nanaco/n →払い戻し不可。/n チャージ残高が消滅し、利用も不可。/n ・WAON/n →払い戻し可能"
        },
        "900": {
            "title": "結局、新NISAってなに？",
            "note": "本来であれば、株を売却した時の利益には、20.315%の税金がかかります。しかしNISA口座で株を買っておくと、それが非課税になります。新NISAでは、さらにその非課税枠が大きく優遇されました。老後2000万円問題に備え、今から投資を始めてみるのも良いのではないでしょうか？/n 【運用イメージ】/n 毎月5万円を20年間積立てたとすると…/n 利回り0%の場合（貯蓄等）：1200万円/n 利回り5%で運用した場合（課税対象）：約1881万円/n 利回り5%で運用した場合（新NISA利用）：約2055万円"
        },
        "1000": {
            "title": "保険で相続税対策ができるってほんと？",
            "note": "終身保険には、「500万円×法定相続人の数」の非課税枠が設けられています。/n 配偶者と子供が2人いる場合は、1500万円の非課税枠が利用できます。/n また保険は、申請後に入金されるので相続財産より早く受け取れる、受取人を指定することで争族トラブルを避けられるといったメリットもあります。/n 尚、相続税には、「3,000万円＋（600万円×法定相続人の数）」という基礎控除があります。上記同様、配偶者と子供2人がいる方は、4800万円まではそもそも非課税ですので、資産がそれ以下の場合、終身保険による節税の必要はありません。"
        },
        "1100": {
            "title": "相続時、住宅ローン残債を支払わずに済む方法！",
            "note": "これから不動産を購入する方は、「団信（団体信用生命保険）」への加入をお勧めします。団信とは、住宅ローン契約時に同時に加入できる生命保険のことで、自分にもしものことがあった際、保険金で住宅ローンの残債を完済することが可能です。/n がん団信や11疾病団信など特約をつけることで、保障範囲を広げられる場合もあります。この保険は住宅ローン契約時にしか加入できないので、注意しましょう。"
        },
        "1200": {
            "title": "ローンや借入金も相続されるの？",
            "note": "結論、借入金やローンなどマイナスの資産も相続されます。しかし相続人が「相続放棄」や「限定承認」を選択することも可能です。/n 【単純承認】/n 相続人が被相続人のすべての財産を無制限・無条件に受け継ぐ、通常の相続です。とくに手続きを取らなければ、自動的に単純承認となります。/n 【相続放棄】/n 相続する権利をすべて放棄することで、最初から相続人でなかったものとみなされます。当然、マイナスの資産を引き継ぐこともありません。相続人がそれぞれ単独で申請でき、相続の開始があったことを知ったときから3ヵ月以内に、家庭裁判所へ申述する必要があります。/n 【限定承認】/n マイナスの財産があった場合、プラスの財産の範囲内で弁済ができます。プラスの財産のほうが多ければ手元に遺産が残り、マイナスの財産のほうが多い場合は、プラスの財産を限度としてマイナスの財産を相続するので、プラスマイナスゼロになります。相続人全員で、相続の開始があったことを知ったときから3ヵ月以内に、家庭裁判所へ申述する必要があります。"
        },
        "1300": {
            "title": "準備中",
            "note": "準備中"
        },
        "1400": {
            "title": "準備中",
            "note": "準備中"
        },
        "1500": {
            "title": "創設が検討される「デジタル遺言制度」",
            "note": "遺言の種類は主に、「自筆証書遺言」と「公正証書遺言」があります。しかし、「自筆証書遺言」は自分で全文記述する必要があるため、不備があると無効になってしまう。「公正証書遺言」には費用と手間がかかるというデメリットがそれぞれあります。/n そこで注目されるのが「デジタル遺言制度」です。デジタル遺言であれば、フォーマットに沿って入力することで、作成の負担や不備を軽減することが期待できます。また、ブロックチェーン技術によって、遺言書の改ざんを防止するという効果も期待できます。政府は、2024年3月に新制度の方向性を提言する予定ですので、引き続き注目していきましょう。"
        },
        "1600": {
            "title": "準備中",
            "note": "準備中"
        },
        "1700": {
            "title": "ご自身が認知症になった時のこと考えていますか？",
            "note": "約4人に1人が認知症になる時代。将来、自分や親の判断能力が低下したときに備えて、任意後見制度の活用を検討するのはいかがでしょうか？/n 認知症になると、さまざまなトラブルに見舞われる可能性がありますが、中でもお金に関するトラブルに不安を抱えている方は多いのではないでしょうか。銀行では認知症と判断されると、口座が凍結されるため、本人であっても引き落としができなくなります。/n そこで本人に代わり、お金の管理者を立てられる制度が成年後見制度です。その制度の「任意後見人制度」を利用すれば、健康なうちに自らの意思で、後見人を指定することができます。契約内容や後見人の報酬も自由に決めることができます。各制度にメリットデメリットはありますが、今のうちから考えておくということが重要です。"
        },
        "1800": {
            "title": "献体すると火葬代が浮く？",
            "note": "献体とは、死後に遺体を解剖教室などに提供し、医学の発展に貢献することです。献体を行うためには、生前から大学や関連団体に登録しておく必要があります。また、献体実施時には、遺族や関係者の同意も必要となります。/n そんな献体を希望する人が近年急速に増えていると言います。昨今の終活ブームにより、「自分が亡くなった後、何かしらの形で世の中に貢献したい」という思いから、献体を希望する方が大多数ですが、献体をすると火葬費用を大学側が負担するため、家族の負担を減らせるというメリットから希望する人も増えているそうです。引き取り手のない遺骨に対してお墓を用意し、供養までしてくれる大学もあり、おひとり様の方が希望するケースも増加しています。/n 尚、ドナー登録をして臓器提供を行なった場合や、そもそも献体が飽和状態で大学側が募集していない場合など、希望していても実際には献体されない場合もあるので理解しておきましょう。"
        },
        "1800": {
            "title": "献体すると火葬代が浮く？",
            "note": "献体とは、死後に遺体を解剖教室などに提供し、医学の発展に貢献することです。献体を行うためには、生前から大学や関連団体に登録しておく必要があります。また、献体実施時には、遺族や関係者の同意も必要となります。/n そんな献体を希望する人が近年急速に増えていると言います。昨今の終活ブームにより、「自分が亡くなった後、何かしらの形で世の中に貢献したい」という思いから、献体を希望する方が大多数ですが、献体をすると火葬費用を大学側が負担するため、家族の負担を減らせるというメリットから希望する人も増えているそうです。引き取り手のない遺骨に対してお墓を用意し、供養までしてくれる大学もあり、おひとり様の方が希望するケースも増加しています。/n 尚、ドナー登録をして臓器提供を行なった場合や、そもそも献体が飽和状態で大学側が募集していない場合など、希望していても実際には献体されない場合もあるので理解しておきましょう。"
        },
        "1900": {
            "title": "互助会で葬儀代を積み立てよう",
            "note": "最近は家族葬や一日葬などコンパクトな葬儀が主流ですが、それでも平均100万円前後の費用がかかります。残された家族が急な出費に困らないよう、互助会で葬儀代を積み立てておくのはいかがでしょうか。/n 全国に200社以上の互助会がありますが、加入するなら大手の互助会が安心です。会員割引で積立金以上の葬儀が行えたり、転居時に移籍がスムーズなどのメリットがあります。/n 尚、互助会に加入した場合は積立金が無駄にならないよう、どこの互助会に加入したか家族にきちんと伝えておくことが重要です。"
        },
        "2000": {
            "title": "生前墓を買うメリット",
            "note": "近年、生前墓を購入するのが一般的となってきました。約5人に1人が生前墓を購入していると言われています。これは先祖代々のお墓など既にお墓がある人を除くと、約半数の人にあたります。生前墓を購入する主な理由は、残される家族に迷惑をかけたくないというものですが、実は税制上のメリットも存在します。/n お墓・仏壇・墓石などの祭祀財産は、相続税の課税対象にならない「非課税財産」として扱われます。相続税は、相続財産の金額によって決まりますので、100万円〜300万円程かかるお墓の費用を、あらかじめ相続財産から減らしておくことができるのです。"
        }
    },
    "questions": {
        "100": {
            "1001": {
                "question": "サブスクリプションサービスをいくつ利用していますか？",
                "label": "サブスク数",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "1~2つ"
                    },
                    {
                        "answer": "3~4つ"
                    },
                    {
                        "answer": "5~6つ"
                    },
                    {
                        "answer": "7つ以上"
                    },
                    {
                        "answer": "利用していない"
                    }
                ]
            },
            "1002": {
                "question": "それらのサブスクリプションサービスの合計月額費用はどれくらいですか？",
                "label": "サブスク合計額",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "1000円未満"
                    },
                    {
                        "answer": "1000円〜3000円程度"
                    },
                    {
                        "answer": "3000円〜5000円程度"
                    },
                    {
                        "answer": "5000円以上"
                    },
                    {
                        "answer": "利用していない"
                    }
                ]
            },
            "1003": {
                "question": "利用中のサブスクリプションサービスを教えてください。",
                "label": "サブスク名",
                "answer_type": "checkbox",
                "answers": [
                    {
                        "answer": "利用していない"
                    },
                    {
                        "answer": "Amazon Prime"
                    },
                    {
                        "answer": "Netflix"
                    },
                    {
                        "answer": "Disney+"
                    },
                    {
                        "answer": "Hulu"
                    },
                    {
                        "answer": "U-next"
                    },
                    {
                        "answer": "Spotify"
                    },
                    {
                        "answer": "Apple Music"
                    },
                    {
                        "answer": "YouTube Premium"
                    },
                    {
                        "answer": "Amazon Music Unlimited"
                    },
                    {
                        "answer": "Google Drive"
                    },
                    {
                        "answer": "iCloud"
                    },
                    {
                        "answer": "Apple One"
                    }
                ]
            },
            "1004": {
                "question": "その他に利用しているサブスクリプションサービスがあれば、ご記入ください。",
                "label": "その他サブスク名",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "200": {
            "2001": {
                "question": "もしもの場合、SNSアカウントはどう扱ってほしいですか？",
                "label": "SNS削除希望",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "すみやかに削除してほしい"
                    },
                    {
                        "answer": "できれば削除しないでほしい"
                    },
                    {
                        "answer": "追悼アカウントに切り替えてほしい"
                    },
                    {
                        "answer": "残された人の判断に委ねる"
                    },
                    {
                        "answer": "利用していない"
                    }
                ]
            }, 
            "2002": {
                "question": "利用中のSNSを教えてください。",
                "label": "SNSサービス名",
                "answer_type": "checkbox",
                "answers": [
                    {
                        "answer": "利用していない"
                    },
                    {
                        "answer": "LINE"
                    },
                    {
                        "answer": "Facebook"
                    },
                    {
                        "answer": "Instagram"
                    },
                    {
                        "answer": "X(Twitter)"
                    },
                    {
                        "answer": "YouTube"
                    },
                    {
                        "answer": "TikTok"
                    },
                    {
                        "answer": "Linkedin"
                    },
                    {
                        "answer": "WhatsApp"
                    },
                    {
                        "answer": "mixi"
                    },
                    {
                        "answer": "note"
                    },
                    {
                        "answer": "アメーバブログ"
                    },
                    {
                        "answer": "はてなブックマーク"
                    }
                ]
            },
            "2003": {
                "question": "その他に利用しているSNSがあれば、ご記入ください。",
                "label": "その他SNS",
                "answer_type": "free",
                "answers": [
                    null
                ]
            },
            "2004": {
                "question": "各アカウントのログイン情報を安全な場所にメモして、メモの保管場所をこちらに入力してください。",
                "label": "SNS保管場所メモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "300": {
            "3001": {
                "question": "メールアドレスをいくつ保有していますか？",
                "label": "メアド数",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "1つ"
                    },
                    {
                        "answer": "2つ"
                    },
                    {
                        "answer": "3つ"
                    },
                    {
                        "answer": "4つ以上"
                    },
                    {
                        "answer": "利用していない"
                    }
                ]
            }, 
            "3002": {
                "question": "利用中のメールサービスを教えてください。",
                "label": "メールサービス名",
                "answer_type": "checkbox",
                "answers": [
                    {
                        "answer": "利用していない"
                    }, {
                        "answer": "Google"
                    },
                    {
                        "answer": "Yahoo!"
                    },
                    {
                        "answer": "Outlook(Hotmail)"
                    },
                    {
                        "answer": "iCloud"
                    },
                    {
                        "answer": "docomo"
                    },
                    {
                        "answer": "au"
                    },
                    {
                        "answer": "softbank"
                    },
                    {
                        "answer": "その他"
                    }
                ]
            },
            "3003": {
                "question": "その他に利用しているメールサービスがあれば、ご記入ください。",
                "label": "その他メールサービス名",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "400": {
            "4001": {
                "question": "写真データを整理していますか？",
                "label": "写真整理状況",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "整理済み"
                    },
                    {
                        "answer": "定期的に整理している"
                    },
                    {
                        "answer": "これから整理したいと思っている"
                    },
                    {
                        "answer": "特に整理するつもりはない"
                    },
                    {
                        "answer": "わからない"
                    }
                ]
            }, 
            "4002": {
                "question": "写真データは主にどこに保存されていますか？",
                "label": "写真保存先",
                "answer_type": "checkbox",
                "answers": [
                    {
                        "answer": "スマートフォン"
                    }, 
                    {
                        "answer": "デスクトップパソコン"
                    },
                    {
                        "answer": "ノートパソコン"
                    },
                    {
                        "answer": "クラウドストレージ"
                    },
                    {
                        "answer": "特定のアプリ内"
                    },
                    {
                        "answer": "その他"
                    }
                ]
            },
            "4003": {
                "question": "写真データの保存先の詳細をメモしておきましょう。",
                "label": "写真保存先メモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "500": {
            "5001": {
                "question": "もしもの場合、スマホやPCなどのデバイスはどう扱ってほしいですか？",
                "label": "デバイスの扱い",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "データを見ないでほしい"
                    },
                    {
                        "answer": "特定のデータ以外は見ないでほしい"
                    },
                    {
                        "answer": "データを見られても気にしない"
                    },
                    {
                        "answer": "データを見てほしい"
                    },
                    {
                        "answer": "残された人の判断に委ねる"
                    }
                ]
            }, 
            "5002": {
                "question": "見てほしいデータがある場合は保存先の詳細をメモしておきましょう。",
                "label": "データ保存先のメモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            },
            "5003": {
                "question": "各デバイスのログイン情報を安全な場所にメモして、メモの保管場所をこちらに入力してください。",
                "label": "デバイスログイン情報の保管場所メモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "600": {
            "6001": {
                "question": "銀行預金の総額はどれくらいですか？（株や保険は除く）",
                "label": "預金額",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "100万円未満"
                    },
                    {
                        "answer": "100万円〜500万円程度"
                    },
                    {
                        "answer": "500万円〜1,000万円程度"
                    },
                    {
                        "answer": "1,000万円〜2,000万円程度"
                    },
                    {
                        "answer": "2,000万円以上"
                    }
                ]
            },
            "6002": {
                "question": "ゆうちょ・信用金庫・ネット銀行なども含めて、銀行口座をいくつ保有していますか？",
                "label": "銀行口座数",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "1つ"
                    },
                    {
                        "answer": "2つ"
                    },
                    {
                        "answer": "3つ"
                    },
                    {
                        "answer": "4つ"
                    },
                    {
                        "answer": "5つ以上"
                    }
                ]
            }, 
            "6003": {
                "question": "利用中の銀行口座のある金融機関を教えてください。",
                "label": "利用銀行名",
                "answer_type": "checkbox",
                "answers": [
                    {
                        "answer": "ゆうちょ銀行"
                    }, 
                    {
                        "answer": "みずほ銀行"
                    },
                    {
                        "answer": "三菱UFJ銀行"
                    },
                    {
                        "answer": "三井住友銀行"
                    },
                    {
                        "answer": "りそな銀行"
                    },
                    {
                        "answer": "埼玉りそな銀行"
                    },
                    {
                        "answer": "信託銀行"
                    },
                    {
                        "answer": "地方銀行"
                    },
                    {
                        "answer": "ネット銀行"
                    },
                    {
                        "answer": "信用金庫"
                    },
                    {
                        "answer": "信用組合"
                    },
                    {
                        "answer": "その他"
                    }
                ]
            },
            "6004": {
                "question": "利用している銀行口座の詳細をメモしておきましょう。（銀行名や支店名など）",
                "label": "銀行口座詳細メモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "700": {
            "7001": {
                "question": "クレジットカードの毎月の平均利用額はどれくらいですか？",
                "label": "クレカ金額",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "1万円未満"
                    },
                    {
                        "answer": "1万円〜3万円程度"
                    },
                    {
                        "answer": "3万円〜5万円程度"
                    },
                    {
                        "answer": "5万円〜10万円程度"
                    },
                    {
                        "answer": "10万円以上"
                    }
                ]
            }, 
            "7002": {
                "question": "クレジットカードを何枚保有していますか？",
                "label": "クレカ枚数",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "1〜2枚"
                    }, 
                    {
                        "answer": "3〜4枚"
                    },
                    {
                        "answer": "5〜6枚"
                    },
                    {
                        "answer": "7枚以上"
                    }
                ]
            },
            "7003": {
                "question": "利用しているクレジットカードの詳細をメモしておきましょう。/nクレジットカード番号は全て記入せず、安全のために下4桁のみをメモしてください。",
                "label": "クレカ詳細メモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "800": {
            "8001": {
                "question": "電子決済サービスの毎月の平均利用額はどれくらいですか？",
                "label": "電子決済利用額",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "1万円未満"
                    },
                    {
                        "answer": "1万円〜2万円程度"
                    },
                    {
                        "answer": "2万円〜3万円程度"
                    },
                    {
                        "answer": "3万円〜5万円程度"
                    },
                    {
                        "answer": "5万円以上"
                    }
                ]
            }, 
            "8002": {
                "question": "交通系ICやQRコード決済など、電子決済サービスをいくつ利用していますか？",
                "label": "電子決済利用数",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "1〜2つ"
                    }, 
                    {
                        "answer": "3〜4つ"
                    },
                    {
                        "answer": "5〜6つ"
                    },
                    {
                        "answer": "7つ以上"
                    },
                    {
                        "answer": "利用していない"
                    }
                ]
            },
            "8003": {
                "question": "利用中の電子決済サービスを教えてください。",
                "label": "電子決済サービス名",
                "answer_type": "checkbox",
                "answers": [
                    {
                        "answer": "利用していない"
                    },
                    {
                        "answer": "Suica"
                    },
                    {
                        "answer": "PASMO"
                    },
                    {
                        "answer": " ICOCA"
                    },
                    {
                        "answer": "manaca"
                    },
                    {
                        "answer": "PayPay"
                    },
                    {
                        "answer": "楽天ペイ"
                    },
                    {
                        "answer": "d払い"
                    },
                    {
                        "answer": "楽天Edy"
                    },
                    {
                        "answer": "WAON"
                    },
                    {
                        "answer": "nanaco"
                    },
                    {
                        "answer": "その他"
                    }
                ]
            },
            "8004": {
                "question": "利用している電子決済サービスの詳細をメモしておきましょう。",
                "label": "電子決済詳細メモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "900": {
            "9001": {
                "question": "株式や投資信託など、保有している有価証券の総額はどれくらいですか？",
                "label": "有価証券総額",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "100万円未満"
                    },
                    {
                        "answer": "100万円〜500万円程度"
                    },
                    {
                        "answer": "500万円〜1,000万円程度"
                    },
                    {
                        "answer": "1,000万円〜2,000万円程度"
                    },
                    {
                        "answer": "2,000万円以上"
                    }
                ]
            }, 
            "9002": {
                "question": "証券口座をいくつ保有していますか？",
                "label": "証券口座数",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "1つ"
                    }, 
                    {
                        "answer": "2つ"
                    },
                    {
                        "answer": "3つ以上"
                    },
                    {
                        "answer": "証券口座はないが証券を保有している"
                    },
                    {
                        "answer": "利用していない"
                    }
                ]
            },
            "9003": {
                "question": "保有している有価証券や証券口座の詳細をメモしておきましょう。（証券会社名や支店名など）",
                "label": "証券口座メモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "1000": {
            "10001": {
                "question": "契約している保険の合計月額費用はどれくらいですか？",
                "label": "保険月額",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "1万円未満"
                    },
                    {
                        "answer": "1万円〜3万円程度"
                    },
                    {
                        "answer": "3万円〜5万円程度"
                    },
                    {
                        "answer": "5万円〜10万円程度"
                    },
                    {
                        "answer": "10万円以上"
                    }
                ]
            }, 
            "10002": {
                "question": "ご自身の名義で生命保険をいくつ契約していますか？",
                "label": "生命保険契約数",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "1件"
                    }, 
                    {
                        "answer": "2件"
                    },
                    {
                        "answer": "3件"
                    },
                    {
                        "answer": "4件以上"
                    },
                    {
                        "answer": "契約していない"
                    }
                ]
            }, 
            "10003": {
                "question": "自動車保険や火災保険など、損害保険をいくつ契約していますか？",
                "label": "損害保険契約数",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "1件"
                    }, 
                    {
                        "answer": "2件"
                    },
                    {
                        "answer": "3件"
                    },
                    {
                        "answer": "4件以上"
                    },
                    {
                        "answer": "契約していない"
                    }
                ]
            },
            "10004": {
                "question": "契約している保険証券の保管先の詳細をメモしておきましょう。",
                "label": "保険証券保管先メモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "1100": {
            "11001": {
                "question": "あなたのお住まいはどちらに当たりますか？",
                "label": "住宅形態",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "賃貸住宅"
                    },
                    {
                        "answer": "持ち家（ローン完済 / 必要なし）"
                    },
                    {
                        "answer": "持ち家（ローン支払い中）"
                    },
                    {
                        "answer": "同居している親族等の持ち家"
                    },
                    {
                        "answer": "その他"
                    }
                ]
            }, 
            "11002": {
                "question": "所有している不動産について教えてください。",
                "label": "所有不動産種類",
                "answer_type": "checkbox",
                "answers": [
                    {
                        "answer": "所有していない"
                    }, 
                    {
                        "answer": "土地"
                    },
                    {
                        "answer": "一軒家"
                    },
                    {
                        "answer": "マンション"
                    }, 
                    {
                        "answer": "田畑"
                    },
                    {
                        "answer": "山林"
                    },
                    {
                        "answer": "その他"
                    }
                ]
            },
            "11003": {
                "question": "不動産の権利書の保管先の詳細をメモしておきましょう。",
                "label": "権利書保管先メモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "1200": {
            "12001": {
                "question": "ローンや借入金の総額はどれくらいですか？",
                "label": "借入金総額",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "ない"
                    },
                    {
                        "answer": "100万円未満"
                    },
                    {
                        "answer": "100万円〜1,000万円程度"
                    },
                    {
                        "answer": "1,000万円〜3,000万円程度"
                    },
                    {
                        "answer": "3,000万円以上"
                    }
                ]
            }, 
            "12002": {
                "question": "連帯保証人など、保証債務はありますか？",
                "label": "クレカ枚数",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "ある"
                    }, 
                    {
                        "answer": "ない"
                    },
                    {
                        "answer": "わからない"
                    }
                ]
            }, 
            "12003": {
                "question": "知人等への貸付金はありますか？",
                "label": "知人への貸付金",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "ある"
                    }, 
                    {
                        "answer": "ない"
                    },
                    {
                        "answer": "わからない"
                    }
                ]
            },
            "12004": {
                "question": "借入金・ローン・保証債務・貸付金の詳細をメモしておきましょう。",
                "label": "借入金などの詳細メモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "1300": {
            "13001": {
                "question": "暗号資産(仮想通貨)やNFTを保有していますか？",
                "label": "暗号資産保有量",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "大量に保有している"
                    },
                    {
                        "answer": "少しだけ保有している"
                    },
                    {
                        "answer": "保有していないが興味がある"
                    },
                    {
                        "answer": "保有するつもりはない"
                    },
                    {
                        "answer": "わからない"
                    }
                ]
            }, 
            "13002": {
                "question": "暗号資産を保有している場合は利用している取引所の詳細をメモしておきましょう。",
                "label": "暗号資産メモ",
                "answer_type": "free",
                "answers":  [
                    null
                ]
            }, 
            "13003": {
                "question": "保有しているその他の資産を教えてください。",
                "label": "その他資産種類",
                "answer_type": "checkbox",
                "answers": [
                    {
                        "answer": "特にない"
                    }, 
                    {
                        "answer": "自動車"
                    },
                    {
                        "answer": "貴金属"
                    },
                    {
                        "answer": "純金積立"
                    }, 
                    {
                        "answer": "美術品"
                    },
                    {
                        "answer": "ブランド品"
                    },
                    {
                        "answer": "ゴルフ会員権"
                    }, 
                    {
                        "answer": "貸金庫の利用"
                    },
                    {
                        "answer": "レンタル倉庫の利用"
                    },
                    {
                        "answer": "その他"
                    }
                ]
            },
            "13004": {
                "question": "前の質問で選んだ資産の詳細をメモしておきましょう。",
                "label": "その他資産詳細メモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "1400": {
            "14001": {
                "question": "一人当たりの毎月の水道光熱費はどれくらいですか？",
                "label": "水道光熱費",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "6,000円以下"
                    },
                    {
                        "answer": "6,000円〜8,000円程度"
                    },
                    {
                        "answer": "8,000円〜1万円程度"
                    },
                    {
                        "answer": "1万円〜1万2,000円程度"
                    },
                    {
                        "answer": "1万2,000円以上"
                    }
                ]
            }, 
            "14002": {
                "question": "ご自身の名義で口座引落としやクレジットカード払いをしているものを教えてください。",
                "label": "契約名義",
                "answer_type": "checkbox",
                "answers": [
                    {
                        "answer": "特にない"
                    }, 
                    {
                        "answer": "家賃"
                    },
                    {
                        "answer": "電気料金"
                    },
                    {
                        "answer": "ガス料金"
                    },
                    {
                        "answer": "水道料金"
                    }, 
                    {
                        "answer": "電話料金"
                    },
                    {
                        "answer": "携帯電気料金"
                    },
                    {
                        "answer": "プロバイダ料金"
                    },
                    {
                        "answer": "NHK受信料"
                    }, 
                    {
                        "answer": "新聞購読料"
                    },
                    {
                        "answer": "その他"
                    }
                ]
            },
            "14003": {
                "question": "その他にご自身の名義で支払いが発生しているものの詳細をメモしておきましょう。",
                "label": "その他支払いメモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "1500": {
            "15001": {
                "question": "遺言書を作成していますか？",
                "label": "遺言書有無",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "公正証書遺言を作成している"
                    },
                    {
                        "answer": "自筆遺言を作成している"
                    },
                    {
                        "answer": "作成していないが興味がある"
                    },
                    {
                        "answer": "作成するつもりはない"
                    },
                    {
                        "answer": "わからない"
                    }
                ]
            },
            "15002": {
                "question": "作成している場合は、遺言書の保管先の詳細をメモしておきましょう。",
                "label": "遺言書保管先メモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "1600": {
            "16001": {
                "question": "直近の健康診断の結果はどれに当たりますか？",
                "label": "健康診断結果",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "異常なし"
                    },
                    {
                        "answer": "経過観察"
                    },
                    {
                        "answer": "再検査"
                    },
                    {
                        "answer": "要精密検査、治療中"
                    },
                    {
                        "answer": "わからない、受けていない"
                    }
                ]
            }, 
            "16002": {
                "question": "かかりつけの病院がある場合は詳細をメモしておきましょう。（病院や担当医師の名前など）",
                "label": "かかりつけ医メモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            },
            "16003": {
                "question": "アレルギーや持病、常用薬などがある場合は詳細をメモしておきましょう。",
                "label": "持病・常備薬メモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "1700": {
            "17001": {
                "question": "介護・認知症の基本方針について教えてください。",
                "label": "介護認知症の考え",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "絶対に自宅で過ごしたい"
                    },
                    {
                        "answer": "できるだけ自宅で過ごしたい"
                    },
                    {
                        "answer": "施設に入居したい"
                    },
                    {
                        "answer": "残された人の判断に委ねる"
                    },
                    {
                        "answer": "わからない"
                    }
                ]
            }, 
            "17002": {
                "question": "介護のための費用について教えてください。",
                "label": "介護費用",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "介護のための預貯金がある"
                    }, 
                    {
                        "answer": "預貯金や年金を使う"
                    },
                    {
                        "answer": "介護のための保険に加入している"
                    },
                    {
                        "answer": "用意していない"
                    },
                    {
                        "answer": "わからない"
                    }
                ]
            },
            "17003": {
                "question": "介護についてすでに決まっていることがある場合は詳細をメモしておきましょう。",
                "label": "介護意思のメモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "1800": {
            "18001": {
                "question": "死期が迫った場合の延命治療についてあなたの考えを教えてください。",
                "label": "延命治療の考え",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "延命治療を望む"
                    },
                    {
                        "answer": "どちらかというと延命治療は望まない"
                    },
                    {
                        "answer": "延命治療は望まないが苦痛を取り除いてほしい"
                    },
                    {
                        "answer": "延命治療は望まない"
                    },
                    {
                        "answer": "わからない"
                    }
                ]
            }, 
            "18002": {
                "question": "余命告知についてあなたの考えを教えてください。",
                "label": "余命告知の考え",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "余命期間に関わらず告知してほしい"
                    }, 
                    {
                        "answer": "余命が一定期間以上の場合は告知してほしい"
                    },
                    {
                        "answer": "病名のみ告知してほしい"
                    },
                    {
                        "answer": "告知はしないでほしい"
                    },
                    {
                        "answer": "わからない"
                    }
                ]
            }, 
            "18003": {
                "question": "臓器提供についてあなたの考えを教えてください。",
                "label": "臓器提供の考え",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "脳死状態になったら臓器提供を希望する"
                    }, 
                    {
                        "answer": "心臓死状態になったら臓器提供を希望する"
                    },
                    {
                        "answer": "臓器提供は希望しない"
                    },
                    {
                        "answer": "残された人の判断に委ねる"
                    },
                    {
                        "answer": "わからない"
                    }
                ]
            },
            "18004": {
                "question": "献体についてあなたの考えを教えてください。",
                "label": "献体の考え",
                "answer_type": "radio",
                "answers":[
                    {
                        "answer": "希望する"
                    }, 
                    {
                        "answer": "希望しない"
                    },
                    {
                        "answer": "わからない"
                    }
                ]
            }
        },
        "1900": {
            "19001": {
                "question": "葬儀の基本方針について教えてください。",
                "label": "葬儀の考え",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "してほしい"
                    },
                    {
                        "answer": "無理のない範囲でしてほしい"
                    },
                    {
                        "answer": "しなくてもよい"
                    },
                    {
                        "answer": "残された人の判断に委ねる"
                    },
                    {
                        "answer": "わからない"
                    }
                ]
            }, 
            "19002": {
                "question": "もしもの場合、自分の状況を連絡して欲しい人はいますか？",
                "label": "もしもの際の連絡",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "家族や親族のみ"
                    }, 
                    {
                        "answer": "すでに連絡先を作成している"
                    },
                    {
                        "answer": "連絡先をこれから作成する"
                    },
                    {
                        "answer": "残された人の判断に委ねる"
                    },
                    {
                        "answer": "特にいない"
                    }
                ]
            }, 
            "19003": {
                "question": "葬儀の宗教について教えてください。",
                "label": "葬儀の宗教",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "仏教"
                    }, 
                    {
                        "answer": "神道"
                    },
                    {
                        "answer": "キリスト教"
                    },
                    {
                        "answer": "その他の宗教"
                    },
                    {
                        "answer": "特にこだわりがない"
                    }
                ]
            },
            "19004": {
                "question": "葬儀についてすでに決まっていることがある場合は詳細をメモしておきましょう。（菩提寺や宗派など）",
                "label": "葬儀の希望のメモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        },
        "2000": {
            "20001": {
                "question": "遺骨の供養の基本方針について教えてください。",
                "label": "遺骨供養の考え",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "一般墓"
                    },
                    {
                        "answer": "納骨堂"
                    },
                    {
                        "answer": "散骨・樹木葬"
                    },
                    {
                        "answer": "残された人の判断に委ねる"
                    },
                    {
                        "answer": "わからない"
                    }
                ]
            }, 
            "20002": {
                "question": "お墓の基本方針について教えてください。",
                "label": "お墓の考え",
                "answer_type": "radio",
                "answers": [
                    {
                        "answer": "先祖代々のお墓に入りたい"
                    }, 
                    {
                        "answer": "新たに購入するお墓に入りたい"
                    },
                    {
                        "answer": "お墓は必要ない"
                    },
                    {
                        "answer": "残された人の判断に委ねる"
                    },
                    {
                        "answer": "わからない"
                    }
                ]
            },
            "20003": {
                "question": "お墓についてすでに決まっていることがある場合は詳細をメモしておきましょう。",
                "label": "お墓の詳細メモ",
                "answer_type": "free",
                "answers": [
                    null
                ]
            }
        }
    }
}
  const answersRef = ref(db);
  update(answersRef, data)
  .then(() => {
      console.log('reset success');
    })
      .catch(() => {
        console.log('reset failed');
      })

}