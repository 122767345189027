import { useEffect } from "react";
import style from "./LibraryCard.module.css"
import IconArrowRight from '@mui/icons-material/ArrowForwardIosRounded';
import { getDatabase } from "@firebase/database";

export const LibraryCard = ({id, number, title, answerStatus, onClick}) => {

    const getDisplayAnswerStatus = (questionId) => {
        if (Object.keys(answerStatus[2]).includes(id)) {
            return 1;
        } else if (
            Object.keys(answerStatus[1]).includes(id) ||
            answerStatus[0][id]["answers"] !== undefined
        ) {
            return 2;
        } else {
            return 3;
        };
    };

    const displayAnswerStatusMessages = Object.freeze({
        1: "回答済み",
        2: "入力中...",
        3: "未入力",
    });

    const getDateFromUnix = (epochMilliSec) => {
        const datetime = new Date(epochMilliSec["updated_at"]);
        const year = datetime.getFullYear();
        const month = datetime.getMonth();
        const date = datetime.getDate();
        if (epochMilliSec !== undefined) {
            return `更新日: ${year}/${month}/${date}`;
        } else {
            return null;
        }
    };

    return (
        <button
            className={style.item}
            onClick={onClick}
            disabled={getDisplayAnswerStatus(id) === 3}
        >
            <span className={`${style.number} ${getDisplayAnswerStatus(id) === 3 && style.disabled}`}>No.{number}</span>
            <div className={style.label}>
                <span className={style.title}>{title}</span>
                <div className={style.status}>
                    <span className={style.status}>{displayAnswerStatusMessages[getDisplayAnswerStatus(id)]}</span>
                    <span className={style.date}>{getDisplayAnswerStatus(id) ===1 ? getDateFromUnix(answerStatus[2][id]["answers"]) : null}</span>
                </div>
            </div>
            {getDisplayAnswerStatus(id) !==3 ? <IconArrowRight /> : null}
        </button>
    );
};