import { get, getDatabase, ref, update } from "firebase/database";
import { useEffect, useState } from "react";
import style from "./SettingAccount.module.css";
import "../GlobalStyle.css";
import PREFECTURES from "../EntryForm/prefecture";
import { handleLogout } from "../../App";

export const SettingAccount = ({userData}) => {
    const [isEditable, setIsEditable] = useState(false);

    const [name, setName] = useState("");
    const [birth, setBirth] = useState("");
    const [sex, setSex] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");

    const db = getDatabase();
    const userRef = ref(db, `users/${userData.uid}`);

    const updateDatabase = async (name, birth, sex, address, email) => {
        update(userRef, {
            user_name: name,
            user_birth: birth,
            user_sex: sex,
            user_address: address,
            address: email,
        }).then(() => {
            console.log("update success");
        }).catch(() => {
            console.log("update failed");
        });
    };

    const getUserData = async () => {
        const userSnapshot = await get(userRef);
        const value = userSnapshot.val()
        
        setName(value.user_name);
        setBirth(value.user_birth);
        setSex(value.user_sex);
        setAddress(value.user_address);
        setEmail(value.address);
    }

    const handleOnSave = (event) => {
        if(name === "") {
            alert("氏名を入力してください");

        } else if(birth === ""){
            alert("生年月日を入力してください");

        } else if(sex === ""){
            alert("性別を入力してください");

        } else if(address === ""){
            alert("住所を入力してください");

        } else if(email === ""){
            alert("メールアドレスを入力してください");

        } else {
            updateDatabase(name, birth, sex, address, email);
            setIsEditable(false);
            getUserData();
        };
    };

    const handleOnEdit = () => {
        setIsEditable(true);
    };

    useEffect(() => {
        getUserData();
    }, []);

    return (
        <div className={style.container}>
            <div className={style.header}>
                <span className={style.title}>基本情報</span>
                {isEditable
                    ? <button onClick={handleOnSave} className={style.button}>保存</button>
                    : <button onClick={handleOnEdit} className={style.button}>編集</button>
                }
            </div>
            <div className={style.infomation}>
                <div className={style.column}>
                    <span className={style.label}>氏名</span>
                    <input
                        type="text"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        disabled={!isEditable} />
                </div>
                <div className={style.column}>
                    <span className={style.label}>生年月日</span>
                    <input
                        type="date"
                        value={birth}
                        onChange={(event) => setBirth(event.target.value)}
                        disabled={!isEditable} />
                </div>
                <div className={style.column}>
                    <span className={style.label}>性別</span>
                    <select
                        value={sex}
                        onChange={(event) => setSex(event.target.value)}
                        disabled={!isEditable}
                    >
                        {["男性", "女性", "回答しない"].map((value, index) => (
                            <option value={value} key={value + index}>{value}</option>
                        ))}
                    </select>
                </div>
                <div className={style.column}>
                    <span className={style.label}>居住地</span>
                    <select
                        value={address}
                        onChange={(event) => setAddress(event.target.value)}
                        disabled={!isEditable}
                    >
                        {PREFECTURES.map((prefecture, index) => (
                            <option value={prefecture} key={prefecture + index}>{prefecture}</option>
                        ))}
                    </select>
                </div>
                <div className={style.column}>
                    <span className={style.label}>メールアドレス</span>
                    <input
                        type="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        disabled={!isEditable} />
                </div>
            </div>
            <div className={style.logout}>
                <button onClick={handleLogout} className={style.button}>ログアウト</button>
            </div>
        </div>
    );
};