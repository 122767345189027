import style from "./FormRadio.module.css";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

export const FormRadio = ({answers, selecting, onChange}) => {
  return (
    <RadioGroup
      aria-label="radios"
      name="radios"
      value={selecting}
      onChange={onChange}
    >
      <div className={style.container}>
        {
          answers.map((answer, index) => (
            <div className={style.item}>
              <FormControlLabel
                value={index}
                control={<Radio />}
                key={index}
                label={<span className={style.label}>{answer.answer}</span>}
                sx={{
                  "&.MuiFormControlLabel-root": {
                    height: "100%",
                    width: "100%",
                    marginLeft: "5px",
                  },
                  ".MuiTypography-root": {
                    marginLeft: "5px",
                  },
                }}
              />
            </div>
          ))
        }
      </div>
    </RadioGroup>
  );
};