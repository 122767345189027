import { Button, Step, StepLabel, Stepper } from "@mui/material";
import style from "./FormTemplate.module.css";
import "../GlobalStyle.css";

export const FormTemplate = ({questionList, card_num, number, title, description, example, children, onClickPrevious, onClickNext, canClickNext}) => {
    const getIsLastQuestion = () => {
        return Object.keys(questionList).length - 1 === Object.keys(questionList).indexOf(number)
    }
    
    return (
        <div className={style.background}>
            <div className={style.container}>
                <div className={style.header}>
                    <span className={style.number}>No. {card_num}</span>
                    <span className={style.title}>{title}</span>
                    <div className={style.progress} style={{width: "80%"}}>
                        <Stepper alternativeLabel activeStep={Object.keys(questionList).indexOf(number)}>
                            {Object.keys(questionList).map((number, index) => (
                                <Step keys={index}>
                                    <StepLabel></StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    <span className={style.description}>{description}</span>
                    {example != null && <span className={style.example}>{example}</span>}
                </div>
                <div className={style.content}>
                    {children}
                </div>
                <div className={style.button}>
                    <Button
                        onClick={onClickPrevious}
                        variant="contained"
                        sx={{
                            '&.MuiButton-root': {
                                backgroundColor: "#e6e6e6",
                                boxShadow: "none",
                                color: "#666666",
                                fontSize: "18px",
                            }
                        }}
                    >
                        前へ
                    </Button>
                    <Button
                        onClick={onClickNext}
                        variant="contained"
                        disabled={!canClickNext}
                        sx={{
                            '&.MuiButton-root': {
                                boxShadow: "none",
                                fontSize: "18px",
                                color: "#ffffff",
                                background: getIsLastQuestion() && "linear-gradient(0.25turn, #EA5A46, #EA9246)",
                            },
                            '&.Mui-disabled': {
                                backgroundColor: "#861506",
                            },
                        }}
                    >
                        {getIsLastQuestion() ? "保存" : "次へ"}
                    </Button>
                </div>
            </div>
        </div>
    );
};