import { useState } from "react";
import style from "./Walkthrough.module.css";
import "../GlobalStyle.css";
import IconDot from '@mui/icons-material/FiberManualRecordRounded';
import { Button } from "@mui/material";

export const Walkthrough = ({onClose}) => {

    const texts = [
        {
            title: <><span className={style.accent}>アカシカ</span>へようこそ!</>,
            description: <><span>全21項目の質問に回答すると</span><span>個人情報が自動で整理されます</span></>,
            image: "/img/walkthrough/walkthrough-1.png",
        },
        {
            title: <><span className={style.accent}>気になる</span>から回答する</>,
            description: <><span>ホームでは、各質問に対する他者の傾向や</span><span>役に立つ終活知識を見ることができます</span></>,
            image: "/img/walkthrough/walkthrough-2.png",
        },
        {
            title: <>シンプルな<span className={style.accent}>解答フォーム</span></>,
            description: <><span>選択肢から選ぶ質問形式が多いので</span><span>手軽に回答することができます</span></>,
            image: "/img/walkthrough/walkthrough-3.png",
        },
        {
            title: <>回答の<span className={style.accent}>見直しも簡単</span></>,
            description: <><span>ライブラリでは、回答の修正や</span><span>入力した個人情報の書き出しができます</span></>,
            image: "/img/walkthrough/walkthrough-4.png",
        },
        {
            title: <>回答して<span className={style.accent}>特典をゲット</span></>,
            description: <><span>すべての質問に回答した方には</span><span>終活プロへの無料相談をプレゼント</span></>,
            image: "/img/walkthrough/walkthrough-5.png",
        },
        {
            title: <><span className={style.accent}>アプリみたい</span>に使える</>,
            description: <><span>iOSは共有内の「ホームに追加」を</span><span>Androidは「インストール」をしましょう</span></>,
            image: "/img/walkthrough/walkthrough-6.png",
        },
    ];

    const [slickPage, setSlickPage] = useState(0);

    const onClickNext = () => {
        if(slickPage < texts.length - 1) {
            setSlickPage(prev => prev + 1);
        } else {
            onClose();
        };
    }
    const onClickPrevious = () => {
        if(slickPage > 0) {
            setSlickPage(prev => prev - 1);
        };
    };

    return (
        <div className={style.container}>
            <img alt={texts[slickPage].image} src={texts[slickPage].image} />
            <div className={style.content}>
                <div
                    className={style.stepper}
                    style={{display: "flex"}}
                >
                    {
                        texts.map((text, index) => (
                            <IconDot className={index === slickPage && style.active} />
                        ))
                    }
                </div>
                <div
                    className={style.texts}
                    style={{display: "flex"}}
                >
                    <span className={style.title}>
                        {texts[slickPage].title}
                    </span>
                    <div className={style.description}>
                        {texts[slickPage].description}
                    </div>
                </div>
                <div
                    className={style.buttons}
                    style={{display: "flex"}}
                >
                    <Button
                        onClick={onClickPrevious}
                        variant="contained"
                        sx={{
                            '&.MuiButton-root': {
                                backgroundColor: "#e6e6e6",
                                boxShadow: "none",
                                color: "#666666",
                                fontSize: "18px",
                                fontWeight: "bold",
                            }
                        }}
                    >
                        前へ
                    </Button>
                    <Button
                        onClick={onClickNext}
                        variant="contained"
                        sx={{
                            '&.MuiButton-root': {
                                boxShadow: "none",
                                fontSize: "18px",
                                color: "#ffffff",
                                fontWeight: "bold",
                                background: slickPage === texts.length - 1 && "linear-gradient(0.25turn, #EA5A46, #EA9246)",
                            },
                        }}
                    >
                        {slickPage === texts.length - 1 ? "完了" : "次へ"}
                    </Button>
                </div>
            </div>
        </div>
    );
};