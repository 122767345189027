import style from "./Empty.module.css";
import IconCheck from "@mui/icons-material/CheckCircleOutlineRounded";
import "../GlobalStyle.css";

export const Empty = () => {
    return (
        <div className={style.container}>
            <IconCheck />
            <span>すべての質問への回答が完了しました！回答の見直しはライブラリから可能です。また、ライブラリから回答を書き出して、家族や大切な人に共有しておくと安心です。特典も忘れずにチェックしてみましょう。</span>
        </div>
    );
};