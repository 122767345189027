import React from "react";
import style from "./LoginPolicy.module.css";
import { Modal, IconButton, } from "@mui/material";
import IconClose from '@mui/icons-material/CancelRounded';
import "../GlobalStyle.css";
import { MarkdownParser } from "../MarkdownParser/MarkdownParser";
import policy from '../Settings/policy.md';
import tou from '../Settings/termofuse.md';

export const LoginPolicy =  ({isShow, onClose, pageType}) => {

  return (
    <Modal
        open={isShow}
        sx={{
          boxSizing: "border-box",
        }}
    >
      <div className={style.container}>
        <div className={style.button}>
          <IconButton
            sx={{
              padding: "0px",
            }}
          >
              <IconClose onClick={onClose} />
          </IconButton>
        </div>
        <MarkdownParser md={pageType === "policy" ? policy : tou} />
      </div>
    </Modal>
  );
};
