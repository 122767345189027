import style from "./MarkdownParser.module.css";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { useState, useEffect } from "react";

export const MarkdownParser = (props) => {
    const [mdText, setMdText] = useState("");

    const getText = async (file) => {
        await fetch(file)
            .then((response) => {
                return response.text();
            })
            .then((text) => {
                setMdText(text);
            });
    }

    useEffect(() => {
        getText(props.md);
    }, [])

    return (
        <Markdown remarkPlugins={[remarkGfm]}>
            {mdText}
        </Markdown>
    );
};