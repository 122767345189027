import style from "./Export.module.css";
import "../GlobalStyle.css";
import IconExport from "@mui/icons-material/IosShareRounded";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";

export const Export = ({answerStatus, userId}) => {
    
    const [isProcessing, setIsProcessing] = useState(false);

    const csvBuilder = (object) => {

        const getCategory = (id) => {
            if (id < 500) {
                return "デジタル情報関連"
            } else if (id < 1300) {
                return "資産関連"
            } else {
                return "意思表示関連"
            };
        };

        const getAnswers = (answerKey, answerValue) => {
            const answers = answerValue["answers"];
            const questions = answerValue["question"];
            const keys = Object.keys(answerValue["question"]);
    
            const result = [];
            keys.forEach((key) => {
                let answer_ = null;
                // freeならそのまま採択
                if (questions[key]['answer_type'] === 'free'){
                    answer_ = answers[key];
                }else{
                // その他はIndex番号なので解釈して帰す
                    const ans_values = questions[key]['answers'];
                    const ans_key = [...answers[key]];
                    const answers_ = []
                    for (const elem of ans_key){
                        answers_.push(ans_values[elem]['answer'])
                    }
                    answer_ = answers_.join(',')
                }
                result.push([
                    `"${answerKey}"`,
                    `"${key}"`,
                    `${getCategory(answerKey)}`,
                    `"${questions[key]["label"]}"`,
                    `"${questions[key]["question"]}"`.replace("\n", ""),
                    `"${questions[key]["answer_type"]}"`,
                    `"${answer_}"`.replace("\n", ""),
                ]);
            });
            return result;
        };

        const answerFormatter = (header, answerObjects) => {
            const keys = Object.keys(answerObjects);
            const result = [header];
            keys.forEach((key) => {
                getAnswers(key, answerObjects[key]).forEach((value) => {
                    result.push(value);
                });
            });
            return result;
        };

        const formattedAnswers = answerFormatter(
            ["question_id", "question_sub_id", "category", "label", "question", "answer_type", "answer"],
            object,
        );

        const result = formattedAnswers.map((value) => (value.join(","))).join("\n");
        return result;
    }

    const handleOnClick = () => {
        if (!isProcessing) {
            setIsProcessing(true);

            const blob = new Blob(
                [csvBuilder(answerStatus["2"])],
                {type: "text/csv;charset=utf-8;"}
            );
            const link = document.createElement("a");
            link.setAttribute("href", URL.createObjectURL(blob));
            link.setAttribute("download", `${userId}-akashica.csv`);
            link.click();

            setIsProcessing(false);
        }
    };

    if(Object.keys(answerStatus["2"]).length >= 7) {
        return (
            <>
                <button
                    className={style.button}
                    onClick={handleOnClick}
                >
                    <IconExport />
                    <span>書き出し</span>
                </button>
                {isProcessing && (
                    <div className={style.loadingBackground}>
                        <CircularProgress />
                        <span className={style.message}>書き出し中...</span>
                    </div>
                )}
            </>
        );
    };
};