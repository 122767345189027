import style from "./BenefitProgress.module.css";
import { LinearProgress } from "@mui/material";
import IconFlag from "@mui/icons-material/FlagRounded";
import "../GlobalStyle.css";

export const BenefitProgress = ({answerdAmount}) => {
    
    const answers = 21;

    const getProgress = (answerdAmount) => {
        return ((answerdAmount / answers) * 100);
    };

    return (
        <div className={style.progress}>
            <div
                className={style.line}
                style={{left: `calc((100% - ${100 - (3 / answers * 100)}%) - 1px)`}}
            >
                <div className={`${style.label} ${answerdAmount >= 3 && style.isCompleted}`}>
                    <span>3</span>
                    <IconFlag />
                </div>
            </div>
            <div
                className={style.line}
                style={{left: `calc((100% - ${100 - (7 / answers * 100)}%) - 1px)`}}
            >
                <div className={`${style.label} ${answerdAmount >= 7 && style.isCompleted}`}>
                    <span>7</span>
                    <IconFlag />
                </div>
            </div>
            <LinearProgress
                variant="determinate"
                value={getProgress(answerdAmount)}
                sx={{
                    height: "24px",
                    borderRadius: "6px",
                    '&.MuiLinearProgress-root': {
                        backgroundColor: "#e6e6e6",
                    },
                    '.MuiLinearProgress-bar': {
                        background: `linear-gradient(0.25turn, #EA5A46 ${100 - getProgress(answerdAmount)}%, #EA9246 100%)`,
                    }
                }}
            />
            <div className={`${style.label} ${style.labelLast} ${answerdAmount >= {answers} && style.isCompleted}`}>
                <span>{answers}</span>
                <IconFlag />
            </div>
        </div>
    );
};