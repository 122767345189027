import React, { useState } from "react";
import style from "./BenfitCard.module.css";
import "../GlobalStyle.css";
import { BenefitCardModal } from "../BenefitCardModal/BenefitCardModal";
import { BenefitProgress } from "../BenefitProgress/BenefitProgress";

export const BenefitCard = ({answerdAmount, benefits}) => {
  const [isShow, setIsShow] = useState(false);
  return (
    <>
      <button className={style.container} onClick={() => setIsShow((prev) => !prev)}>
        <div className={style.header}>
          <span className={style.text}>終活を進めて特典をゲットしよう</span>
          <div className={style.button}>
            <span>Tap</span>
          </div>
        </div>
        <BenefitProgress answerdAmount={answerdAmount} />
      </button>
      <BenefitCardModal
        isShow={isShow}
        onClose={() => setIsShow((prev) => !prev)}
        answerdAmount={answerdAmount}
        benefits={benefits}
      />
    </>
  );
};
